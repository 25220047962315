/* Akadimia HTML Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header
5. Featured Courses
6. Sidebar Menu
7. Sidebar Pages
8. Latest Courses
9. Popular Instructors
10. Testimonial Section
11. Sidebar Widgets
12. Team Section
13. Video Section
14. Development Section
15. Skills Section
16. Pricing Section
17. Testing Section
18. Profile Section
19. Coming Soon
20. Not Found
21. Feedback Section
22. Help Section
23. Blog Section
24. Blog Widgets
25. Blog Detail Section
26. Setting Section
27. Login Section
28. Signup Section
29. Certificate Section
30. Testing Section
31. Membership Section
32. Checkout Section
33. Contact Us Section
34. Enrolled Cource Section
35. Dashboard Section
36. Earning Section
37. Manage Cource Section
38. Edit Cource Section

**********************************************/

/*

	font-family: 'Montserrat', sans-serif;
	font-family: 'Roboto', sans-serif;

*/

/* @import url('font-awesome.css');
@import url('flaticon.css');
@import url('animate.css');
@import url('owl.css');
@import url('jquery-ui.css');
@import url('animation.css');
@import url('jquery.fancybox.min.css');
@import url('jquery.bootstrap-touchspin.css');
@import url('jquery.mCustomScrollbar.min.css'); */

/*** 

====================================================================
	Reset
====================================================================

 ***/
 
* {
	margin:0px;
	padding:0px;
	border:none;
	outline:none;
}

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
	font-family: 'Montserrat', sans-serif;
	font-size:14px;
	color:#777777;
	line-height:1.7em;
	font-weight:400;
	background:#f7f8fc;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
}

.bordered-layout .page-wrapper{
	padding:0px 50px 0px;
}

a{
	text-decoration:none;
	cursor:pointer;
	color:#ff5773;
}

button,
a:hover,a:focus,a:visited{
	text-decoration:none;
	outline:none !important;
}

h1,h2,h3,h4,h5,h6 {
	position:relative;
	font-weight:normal;
	margin:0px;
	background:none;
	line-height:1.6em;
	font-family: 'Roboto', sans-serif;
}

/* Typography */

h1{
	font-size:72px;
}

h2{
	font-size:36px;
}

h3{
	font-size:30px;
}

h4{
	font-size:22px;
}

h5{
	font-size:20px;
}

h6{
	font-size:18px;
}

input,button,select,textarea{
	
}

textarea{
	overflow:hidden;
}

p{
	position:relative;
	line-height:1.8em;	
}

.auto-container{
	position:static;
	max-width:2400px;
	padding:0px 15px;
	margin:0 auto;
}

.medium-container{
	max-width:850px;
}

.page-wrapper{
	position:relative;
	margin:0 auto;
	width:100%;
	min-width:300px;
	background-color:#f8f9fd;
	padding-left:70px;
}

.page-wrapper.no-padding{
	padding-left:0px;
}

ul,li{
	list-style:none;
	padding:0px;
	margin:0px;	
}

img{
	display:inline-block;
	max-width:100%;
}

.theme-btn{
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.centered{
	text-align:center;	
}

/*Btn Style One*/

.btn-style-one{
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 30px;
	color: #ffffff;
	padding: 7px 40px;
	font-weight: 400;
	overflow: hidden;
	border-radius: 50px;
	overflow:hidden;
	text-transform: capitalize;
	background-color:#ff5773;
	box-shadow:0px 10px 15px rgba(255,87,115,0.10);
}

.btn-style-one:before{
	position: absolute;
	content: '';
    top: -2px;
    left: -2px;
    right:-2px;
	bottom:-2px;
    z-index: 1;
    opacity: 0;
    background-color: #222222;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
}

.btn-style-one .txt{
	position:relative;
	z-index:1;
}

.btn-style-one:hover::before{
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-one:hover{
	color: #ffffff;
	border-color:#00e49a;
}

/* Btn Style Two */

.btn-style-two{
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 30px;
	color: #ff5773;
	padding: 6px 40px;
	font-weight: 400;
	overflow: hidden;
	border-radius: 50px;
	overflow:hidden;
	text-transform: capitalize;
	border:1px solid #ff5773;
}

.btn-style-two:before{
	position: absolute;
	content: '';
    top: -2px;
    left: -2px;
    right:-2px;
	bottom:-2px;
    z-index: 1;
    opacity: 0;
    background-color: #ff5773;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
}

.btn-style-two .txt{
	position:relative;
	z-index:1;
}

.btn-style-two:hover::before{
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-two:hover{
	color: #ffffff;
	border-color:#ff5773;
}

/* Btn Style Three */

.btn-style-three{
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 30px;
	color: #ff5773;
	padding: 13px 52px;
	font-weight: 500;
	overflow: hidden;
	border-radius: 50px;
	overflow:hidden;
	text-transform: capitalize;
	border:2px solid #ff5773;
}

.btn-style-three:before{
	position: absolute;
	content: '';
    top: -2px;
    left: -2px;
    right:-2px;
	bottom:-2px;
    z-index: 1;
    opacity: 0;
    background-color: #ff5773;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
}

.btn-style-three .txt{
	position:relative;
	z-index:1;
}

.btn-style-three:hover::before{
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-three:hover{
	color: #ffffff;
	border-color:#ff5773;
}

/* Btn Style Four */

.btn-style-four{
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 30px;
	color: #ffffff;
	padding: 13px 52px;
	font-weight: 500;
	overflow: hidden;
	border-radius: 50px;
	overflow:hidden;
	text-transform: capitalize;
	border:1px solid #ffffff;
}

.btn-style-four:before{
	position: absolute;
	content: '';
    top: -2px;
    left: -2px;
    right:-2px;
	bottom:-2px;
    z-index: 1;
    opacity: 0;
    background-color: #ff5773;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
}

.btn-style-four .txt{
	position:relative;
	z-index:1;
}

.btn-style-four:hover::before{
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-four:hover{
	color: #ffffff;
	border-color:#ff5773;
}

/*Social Icon One*/

.social-icon-one{
	position:relative;
}

.social-icon-one li{
	position:relative;
	margin-right:10px;
	display:inline-block;
}

.social-icon-one li a{
	position:relative;
	width:40px;
	height:40px;
	font-size:15px;
	color:#ffffff;
	font-weight:400;
	border-radius:50px;
	text-align:center;
	line-height:38px;
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	border:1px solid rgba(255,255,255,0.30);
}

.social-icon-one li a:hover{
	color:#ffffff;
	border-color:#ffdb4c;
	background-color:#ffdb4c;
}

.theme_color{
	color:#ff5773;
}

.preloader{ position:fixed; left:0px; top:0px; width:100%; height:100%; z-index:999999; background-color:#ffffff; background-position:center center; background-repeat:no-repeat; background-size:90px; display:none; }

img{
	display:inline-block;
	max-width:100%;
	height:auto;	
}

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top{
	position:fixed;
	bottom:15px;
	right:15px;
	width:60px;
	height:60px;
	color:#ffffff;
	font-size:24px;
	text-transform:uppercase;
	line-height:60px;
	text-align:center;
	z-index:100;
	cursor:pointer;
	background:#0a0a0a;
	display:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;		
}

.scroll-to-top:hover{
	color:#ffffff;
	background:#135ff9;
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title{
	position:relative;
	z-index:1;
	margin-bottom:20px;
}

.sec-title h4{
	position:relative;
	color:#0f0e2d;
	font-weight:700;
	line-height:1.3em;
}

.sec-title .see-all{
	position:relative;
	color:#ff5773;
	font-size:12px;
	font-weight:600;
	line-height:1.3em;
	letter-spacing:1px;
	border-bottom:1px solid #ff5773;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sec-title .see-all:hover{
	
}

.sec-title.centered{
	text-align: center !important;
}

.sec-title.light h2{
	color:#ffffff;
}

/* List Style One */

.list-style-one{
	position:relative;
	z-index:1;
}

.list-style-one li{
	position:relative;
	color:#71718a;
	font-size:13px;
	font-weight:500;
	margin-bottom:10px;
	padding-left:26px;
	line-height:1.5em;
}

.list-style-one li:before{
	position:absolute;
	left:0px;
	content: "\f144";
	color:#0e7bfe;
	font-size:13px;
	font-weight:400;
	display:block;
	font-family: "Flaticon";
}

/*** 

====================================================================
	Main Header style
====================================================================

***/

.main-header{
	position:relative;
	z-index:9999;
	width:100%;
	background-color:#ffffff;
	-ms-animation-duration: 500ms;
    -moz-animation-duration: 500ms;
    -op-animation-duration: 500ms;
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
}

.main-header .header-upper{
	margin-left:-70px;
}

.main-header .left-sidebar-btn{
	position:relative;
	width:70px;
	height:70px;
	color:#ffffff;
	float:left;
	cursor:pointer;
	margin-right:20px;
	line-height:80px;
	font-size:28px;
	text-align:center;
	background-color:#ff5773;
}

.main-header .header-left{
	position:relative;
	float:left;
}

/*Search Box Widget*/

.main-header .header-left .search-box{
	position:relative;
	float:left;
	padding:10px 0px;
	margin-left:35px;
}

.main-header .header-left .search-box .form-group{
	position:relative;
	margin:0px;	
}

.main-header .header-left .form-group input[type="text"],
.main-header .header-left .form-group input[type="search"]{
	position:relative;
	line-height:30px;
	padding:10px 50px 10px 30px;
	background:#feeff2;
	display:block;
	font-size:15px;
	width:100%;
	height:50px;
	color:#000000;
	width:400px;
	font-weight:400;
	border-radius:50px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Roboto', sans-serif;
}

.main-header .header-left .form-group textarea::-webkit-input-placeholder,
.main-header .header-left .form-group input::-webkit-input-placeholder{
	color:#9c9c9c;
}

.main-header .header-left .search-box .form-group button{
	position:absolute;
	right:15px;
	top:6px;
	height:36px;
	width:36px;
	display:block;
	font-size:18px;
	color:#ff5773;
	cursor:pointer;
	line-height:34px;
	border-radius:50px;
	line-height:100%;
	font-weight:normal;
	background:none;
}

.main-header .header-right{
	position:relative;
	float:right;
	padding-right:50px;
}

.main-header .header-right .options-box{
	position:relative;
	float:right;
	padding:22px 0px;
}

.main-header .header-right .options-box .message-box{
	position:relative;
	float:left;
	color:#959398;
	font-size:16px;
}

.main-header .header-right .options-box .message-box a{
	position:relative;
	color:#959398;
}

/* Notification Box */

.main-header .header-right .options-box .notification-box{
	position:relative;
	float:left;
	color:#b8b7ba;
	background:none;
	margin-top:0px;
	margin-left:12px;
	display:inline-block;
}

.main-header .header-right .options-box .notification-box .notification-alert{
	position:relative;
	top:-11px;
	right:4px;
	width:4px;
	height:4px;
	border-radius:50px;
	display:inline-block;
	background-color:#ff5773;
}

.main-header .header-right .options-box .notification-box .notification-box-btn{
	background:none;
	color:#b8b7ba;
	cursor: pointer;
	font-size: 18px;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .header-right .options-box .notification-box .notification-box-btn:after{
	display:none;
}

.main-header .header-right .options-box .notification-box .notification-box-btn:hover{
	color:#ff5773;
}

.main-header .header-right .options-box .notification-block{
	position:relative;
	padding:20px 22px 18px;
	margin-bottom:0px;
	border-bottom:1px solid #f1f3ff;
}

.main-header .header-right .options-box .notification-block .inner{
	position:relative;
	padding-left:45px;
}

.main-header .header-right .options-box .notification-block .inner .image{
	position:absolute;
	width:30px;
	height:30px;
	left:0px;
	top:0px;
	overflow:hidden;
	border-radius:50px;
}

.main-header .header-right .options-box .notification-block .inner .image img{
	width:100%;
	display:block;
}

.main-header .header-right .options-box .notification-block .inner h3{
	position:relative;
	font-weight:500;
	font-size:14px;
	margin:0px;
	text-transform:capitalize;
	font-family: 'Roboto', sans-serif;
}

.main-header .header-right .options-box .notification-block .inner h3 a{
	color:#0f0e2d;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .header-right .options-box .notification-block .inner h3 a:hover{
	color:#ff5773;
}

.main-header .header-right .options-box .notification-block .inner .notification-text{
	position:relative;
	font-weight:400;
	color:#71718a;
	font-size: 14px;
	line-height:1.6em;
	margin-bottom: 0px;
	margin-top:8px;
}

.main-header .header-right .options-box .notification-block .inner .post-time{
	position:relative;
	font-weight:500;
	font-size:12px;
	margin:0px;
	color:#b6b9cd;
	margin-top:8px;
}

.main-header .header-right .options-box .notification-box .cart-panel{
	left: auto !important;
	right: 0px !important;
	border-radius:0px 0px 4px 4px;
	padding:0px 0px;
	min-width:320px;
	margin-top:48px;
	z-index: 1;
	border:0px;
	box-shadow: 0px 0px 25px rgba(0,0,0,0.15);
	-webkit-transform: translate3d(0px, 0px, 0px) !important;
	transform: translate3d(0px, 0px, 0px) !important;
}

.main-header .header-right .options-box .notification-box .view{
	position:relative;
	display:block;
	color:#ff5773;
	font-size:12px;
	font-weight:600;
	padding:13px 15px;
	text-align:center;
	background-color:#feeff2;
	text-decoration:underline;
}

.main-header .header-right .options-box .profile-box{
	position:relative;
	float:left;
	margin-left:12px;
}

.main-header .header-right .options-box .profile-box .profile-image{
	position:relative;
	width:24px;
	height:24px;
	overflow:hidden;
	border-radius:50px;
	display:inline-block;
}

.main-header .header-right .options-box .profile-box .arrow{
	position:relative;
	top:-4px;
	color:#b1afb3;
	margin-left:6px;
}

.main-header .header-right .options-box .profile-box .dropdown-toggle{
	padding:0px;
	border:0px;
	line-height:1em;
}

.main-header .header-right .options-box .profile-box .dropdown-toggle:focus{
	outline:none;
	box-shadow:none;
}

.main-header .header-right .options-box .profile-box .dropdown-toggle:after{
	display:none;
}

.main-header .header-right .options-box .profile-box .profile-content{
	position:absolute;
	right:0px;
	top:49px;
	width:290px;
	display:none;
	background-color:#ffffff;
	border-radius:0px 0px 5px 5px;
	box-shadow:0px 0px 15px rgba(0,0,0,0.10);
}

.main-header .header-right .options-box .profile-box.dropdown-active .profile-content{
	display:block;
}

.main-header .header-right .options-box .profile-box .profile-content .setting{
	position:relative;
	padding:20px 25px 20px;
	color:#222222;
	font-weight:600;
	border-bottom: 1px solid #f1f3ff;
}

.main-header .header-right .options-box .profile-box .profile-content .setting a{
	position:relative;
	color:#222222;
}

.main-header .header-right .options-box .profile-box .profile-content h6{
	position:relative;
	color:#252841;
	font-size:14px;
	padding:20px 30px;
	border-bottom:1px solid #f1f3ff;
}

.main-header .header-right .options-box .profile-box .profile-content .dark-mode{
	position:relative;
	padding:22px 30px;
	border-bottom:1px solid #f1f3ff;
}

.main-header .header-right .options-box .profile-box .profile-content .dark-mode .mode{
	position:relative;
	font-size:14px;
	font-weight:500;
	padding-top:5px;
	padding-left:40px;
	font-family: 'Montserrat', sans-serif;
}

.main-header .header-right .options-box .profile-box .profile-content .dark-mode .icon{
	position:absolute;
	left:0px;
	top:0px;
	width:30px;
	height:30px;
	line-height:30px;
	text-align:center;
	border-radius:50px;
	display:inline-block;
	background-color:#feeff2;
}

/* Dark Buttons */

.main-header .header-right .options-box .profile-box .profile-content .dark-buttons{
	position:relative;
	margin-top:9px;
}

.main-header .header-right .options-box .profile-box .profile-content .dark-buttons li{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:400;
	float:left;
	cursor:pointer;
	margin:0px 0px;
	line-height:16px;
	text-transform:capitalize;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .header-right .options-box .profile-box .profile-content .dark-buttons li.off:before{
	position:absolute;
	content:'';
	left:28px;
	top:0px;
	width:15px;
	height:15px;
	z-index:10;
	border-radius:50px 0px 0px 50px;
}

.main-header .header-right .options-box .profile-box .profile-content .dark-buttons li.on:before{
	position:absolute;
	content:'';
	right:28px;
	top:0px;
	width:15px;
	height:15px;
	z-index:10;
	border-radius:0px 50px 50px 0px;
}

.main-header .header-right .options-box .profile-box .profile-content .dark-buttons .boll{
	position:relative;
	width: 30px;
    height: 15px;
	padding:0px 0px 0px;
	border-radius:50px;
	float:left;
	margin:0px 10px;
	text-align:left;
	background-color:#f5f6fa;
}

.main-header .header-right .options-box .profile-box .profile-content .dark-buttons .boll .round{
	position:relative;
	top:-1px;
	width:7px;
	height:7px;
	left:3px;
	border-radius:50px;
	display:inline-block;
	background-color:#71718a;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .header-right .options-box .profile-box .profile-content .dark-buttons .boll .round.boll-right{
	transform:translateX(16px);
}

.main-header .header-right .options-box .profile-box .profile-content .dark-buttons .tab-btn:hover,
.main-header .header-right .options-box .profile-box .profile-content .dark-buttons .tab-btn.active-btn{
	color:#222222;
}

.main-header .header-right .options-box .profile-box .pages{
	position:relative;
	padding:30px 30px;
}

.main-header .header-right .options-box .profile-box .pages li{
	position:relative;
	margin-bottom:13px;
}

.main-header .header-right .options-box .profile-box .pages li:last-child{
	margin-bottom:0px;
}

.main-header .header-right .options-box .profile-box .pages li a{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:500;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Montserrat', sans-serif;
}

.main-header .header-right .options-box .profile-box .pages li a:hover{
	color:#ff5773;
}







.main-header .main-box{
	position:relative;
	padding:0px 0px;
	left:0px;
	top:0px;
	width:100%;
	background:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .main-box .outer-container{
	position:relative;
	padding:0px 40px;
}

.main-header .main-box .logo-box{
	position:relative;
	float:left;
	left:0px;
	z-index:10;
	padding:30px 0px;
}

.main-header .main-box .logo-box .logo img{
	display:inline-block;
	max-width:100%;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.main-header .header-upper .outer-box{
	position:relative;
	float:right;
	padding-top:28px;
	padding-left:10px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .header-upper .outer-box .nav-toggler{
	position:relative;
	font-size:28px;
	color:#ff9600;
	cursor:pointer;
	display:inline-block;
}

.main-header .header-upper .outer-box .language-list{
	position:relative;
	margin-left:25px;
	top:-5px;
	display:inline-block;
}

.main-header .header-upper .outer-box .language-list li{
	position:relative;
	padding-right:15px;
	margin-right:5px;
	display:inline-block;
}

.main-header .header-upper .outer-box .language-list li:last-child{
	margin-right:0px;
	padding-right:0px;
}

.main-header .header-upper .outer-box .language-list li:last-child::before{
	display:none;
}

.main-header .header-upper .outer-box .language-list li:before{
	position:absolute;
	content:'/';
	right:0px;
	top:0px;
	color:#ffffff;
}

.main-header .header-upper .outer-box .language-list li a{
	position:relative;
	color:#ffffff;
	font-size:16px;
}

/*Sticky Header*/

.sticky-header{
	position:fixed;
	opacity:0;
	visibility:hidden;
	left:0px;
	top:0px;
	width:100%;
	padding:0px 0px;
	z-index:0;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.sticky-header .logo{
	padding:8px 0px 8px;
}

.fixed-header .sticky-header{
	z-index:999;
	opacity:1;
	visibility:visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-op-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-op-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-op-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-op-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;	
}

.sticky-header .main-menu .navigation > li > a{
	padding:19px 0px  !important;
	color:#ffffff !important;
}

.sticky-header .main-menu .navigation > li > a:after{
	display:none;
}

.main-header .nav-outer{
	position:static;
	float: left;
	padding-top:0px;
	transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-header .header-upper .logo-box{
	position: relative;
	z-index:12;
	padding: 10px 0px 10px;
	transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-header .header-upper .logo-box .logo{
	position:relative;
}

.main-header .header-upper .logo-box .dark-layout-logo{
	position:absolute;
	left:0px;
	top:10px;
	opacity:0;
	visibility:hidden;
}

.main-header .header-lower{
	position:relative;
	
	background-color:rgba(0,0,0,0.55);
}

.main-menu{
	position:static;
	float:left;
	transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.main-menu .navbar-collapse{
	padding:0px;
	float: left;
}

.main-menu .navigation{
	position:static;
	margin:0px;
}

.main-menu .navigation > li{
	position:relative;
	float:left;
	padding:23px 0px;
	margin-right:25px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .navigation > li.dropdown > a{
	
}

.main-menu .navigation > li:last-child{
	
}

.main-header .header-upper .upper-right{
	position:relative;
}

.main-header .header-upper .info-box{
	position:relative;
	color:#666666;
	font-weight:400;
	font-size:15px;
	margin-right:30px;
	padding-left:40px;
	display:inline-block;
}

.main-header .header-upper .info-box .icon-box{
	position:absolute;
	left:0px;
	top:4px;
	line-height:1em;
}

.main-header .header-upper .info-box strong{
	position:relative;
	color:#222222;
	font-size:17px;
	font-weight:500;
	margin-bottom:5px;
	display:block;
	font-family: 'Jost', sans-serif;
}

.main-header .header-upper .info-box strong a{
	position:relative;
	color:#222222;
}

.main-header .header-upper .upper-right .social-box{
	position:relative;
	padding-top:13px;
}

.main-header .header-upper .upper-right .social-box a{
	position:relative;
	color:#c1c1c1;
	font-size:20px;
	margin-left:10px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.main-header .header-upper .upper-right .social-box a:hover{
	color:#032a53;
}

.main-menu .navigation > li > a{
	position: relative;
    color: #ff5773;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Poppins', sans-serif;
}

.main-menu .navigation > li:hover > a::after,
.main-menu .navigation > li.current > a::after{
	width:22px;
}

.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a,
.main-header.light-version .main-menu .navigation > li:hover > a,
.main-header.light-version .main-menu .navigation > li.current > a{
	opacity:1;
}

.main-menu .navigation > li > ul{
	position:absolute;
	left:0px;
	top:100%;
	width:230px;
	z-index:100;
	display:none;
	opacity: 0;
	text-align:left;
    visibility: hidden;
    transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	padding: 0px 0px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
}

.main-menu .navigation > li > ul:before{
	position:absolute;
	content:'';
	left:0px;
	top:-30px;
	width:100%;
	height:30px;
	display:block;
}

.main-menu .navigation > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-menu .navigation > li > ul > li{
	position:relative;
	width:100%;
	border-bottom: 1px solid #f4f2f2;
}

.main-menu .navigation > li > ul > li:last-child{
	border-bottom:none;
}

.main-menu .navigation > li > ul > li > a{
	position:relative;
	display:block;
	padding:12px 18px;
	line-height:24px;
	font-weight:400;
	font-size:16px;
	color:#222222;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Roboto', sans-serif;
}

.main-menu .navigation > li > ul > li:hover > a{
	color:#ffffff;
	padding-left:35px;
	background:#ff5773;
}

.main-menu .navigation > li > ul > li.dropdown > a:after{
	font-family: 'FontAwesome';
	content: "\f105";
	position:absolute;
	left:18px;
	top:15px;
	width:10px;
	height:20px;
	display:block;
	color:#ffffff;
	line-height:20px;
	font-size:16px;
	font-weight:normal;
	text-align:center;
	z-index:5;
	opacity:0;
	transform:rotate(180deg);
}

.main-menu .navigation > li > ul > li.dropdown:hover > a:after{
	color:#ffffff;
	opacity:1;
}

.main-menu .navigation > li > ul > li > ul{
	position:absolute;
	right:100%;
	top:100%;
	width:230px;
	z-index:100;
	display:none;
	text-align:left;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
    padding: 0px 0px;
	background-color: #ffffff;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.main-menu .navigation > li > ul > li > ul.from-right{
	left:0px;
	right:auto;	
}

.main-menu .navigation > li > ul > li > ul > li{
	position:relative;
	width:100%;
	border-bottom: 1px solid #f4f2f2;
}

.main-menu .navigation > li > ul > li > ul > li:last-child{
	border-bottom:none;	
}

.main-menu .navigation > li > ul > li > ul > li > a{
	position:relative;
	display:block;
	padding:12px 18px;
	line-height:24px;
	font-weight:400;
	font-size:16px;
	color:#222222;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Roboto', sans-serif;
	
}

.main-menu .navigation > li > ul > li > ul > li:hover > a{
	color:#ffffff;
	padding-left:30px;
	background:#ff5773;
}

.main-menu .navigation > li > ul > li > ul > li.dropdown > a:after{
	font-family: 'FontAwesome';
	content: "\f105";
	position:absolute;
	right:10px;
	top:11px;
	width:10px;
	height:20px;
	display:block;
	color:#272727;
	line-height:20px;
	font-size:16px;
	font-weight:normal;
	text-align:center;
	z-index:5;	
}

.main-menu .navigation > li > ul > li > ul > li.dropdown:hover > a:after{
	color:#ffffff;	
}

.main-menu .navigation > li.dropdown:hover > ul{
	visibility:visible;
	opacity:1;
	border-top:0px !important;
	-moz-transform: rotateX(0deg);
	-webkit-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	-o-transform: rotateX(0deg);
    transform: rotateX(0deg);
}

.main-menu .navigation li > ul > li.dropdown:hover > ul{
	visibility:visible;
	opacity:1;
	top:0;
	-moz-transform: rotateX(0deg);
	-webkit-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	-o-transform: rotateX(0deg);
	transform: rotateX(0deg);
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;	
}

.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn{
	position:absolute;
	right:10px;
	top:6px;
	width:30px;
	height:30px;
	text-align:center;
	color:#ffffff;
	line-height:28px;
	border:1px solid #ffffff;
	background-size:20px;
	cursor:pointer;
	z-index:5;
	display:none;
}

.main-header .header-upper .outer-box .search-box-outer{
	position: relative;
	float:left;
}

.main-header .header-upper .outer-box .search-box-btn{
	position:relative;
	width:42px;
	height:48px;
	display:block;
	font-size:18px;
	color:#696969;
	cursor: pointer;
	background:none;
	line-height:48px;
	text-align:right;
	display:inline-block;
	line-height:20px !important;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .header-upper .outer-box .search-box-btn:after{
	display:none;
}

.main-header.fixed-header .header-upper{
	position: fixed;
	left:0px;
	top:0px;
    z-index: 9999;
    width: 100%;
	margin-left:0px;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
	background-color:#ffffff;
	box-shadow:0px 10px 10px rgba(0,0,0,0.02);
}

.main-header.fixed-header .header-upper .logo-box{
	padding:10px 0px;
}

.main-header.fixed-header .nav-outer{
	padding-top:0px;
}

.main-header.fixed-header .main-menu .navigation > li > a{
	padding:28px 0px;
}

.main-header.fixed-header .header-upper .outer-box{
	padding-top:18px;
}

.main-header .header-upper .outer-box .login-box{
	position:relative;
	width:52px;
	height:52px;
	float:left;
	color:#ffffff;
	font-size:18px;
	cursor:pointer;
	line-height:50px;
	text-align:center;
	border-radius:50px;
	display:inline-block;
	border:1px solid rgba(255,255,255,0.50);
}

.main-header .header-upper .outer-box .sidebar-btn{
	position:relative;
	width:52px;
	height:52px;
	float:left;
	color:#ffffff;
	font-size:18px;
	margin-left:14px;
	cursor:pointer;
	line-height:52px;
	text-align:center;
	border-radius:50px;
	background-color:#05db56;
}

.main-header .header-lower .options-box{
	position:relative;
	float:left;
	margin-left:30px;
	padding:28px 0px;
}

.main-header .header-lower .logo{
	position:relative;
	padding:7px 0px;
}

/*** 

====================================================================
	Mobile Menu
====================================================================

***/

.nav-outer .mobile-nav-toggler{
	position: relative;
	float: right;
	font-size: 36px;
	line-height: 50px;
	cursor: pointer;
	color:#000000;
	display: none;
}

.mobile-menu{
	position: fixed;
	right: 0;
	top: 0;
	width: 300px;
	padding-right:30px;
	max-width:100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
}

.mobile-menu .nav-logo{
	position:relative;
	padding:20px 20px;
	text-align:left;	
}

.mobile-menu .nav-logo img{
	max-width:200px;
}

.mobile-menu-visible{
	overflow: hidden;
}

.mobile-menu-visible .mobile-menu{
	opacity: 1;
	visibility: visible;
}

.mobile-menu .menu-backdrop{
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(0,0,0,0.90);
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-backdrop{
	opacity: 1;
	visibility: visible;
	-webkit-transition:all 0.7s ease;
	-moz-transition:all 0.7s ease;
	-ms-transition:all 0.7s ease;
	-o-transition:all 0.7s ease;
	transition:all 0.7s ease;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}

.mobile-menu .menu-box{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	background: #ffffff;
	padding: 0px 0px;
	z-index: 5;
	opacity: 0;
	visibility: hidden;
	border-radius: 0px;
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
}

.mobile-menu-visible .mobile-menu .menu-box{
	opacity: 1;
	visibility: visible;
	-webkit-transition:all 0.7s ease 500ms;
	-moz-transition:all 0.7s ease 500ms;
	-ms-transition:all 0.7s ease 500ms;
	-o-transition:all 0.7s ease 500ms;
	transition:all 0.7s ease 500ms;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}

.mobile-menu .close-btn{
	position: absolute;
	right: 3px;
	top: 3px;
	line-height: 30px;
	width: 30px;
	text-align: center;
	font-size: 14px;
	color: #202020;
	cursor: pointer;
	z-index: 10;
	-webkit-transition:all 0.5s ease;
	-moz-transition:all 0.5s ease;
	-ms-transition:all 0.5s ease;
	-o-transition:all 0.5s ease;
	transition:all 0.5s ease;
	-webkit-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	transform: translateY(-50px);
}

.mobile-menu-visible .mobile-menu .close-btn{
	-webkit-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
}

.mobile-menu .close-btn:hover{
	opacity: 0.50;
}

.mobile-menu .navigation{
	position: relative;
	display: block;
	width:100%;
	border-top: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li{
	position: relative;
	display: block;
	border-bottom: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li > ul > li:last-child{
	border-bottom: none;
}

.mobile-menu .navigation li > ul > li:first-child{
	border-top: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li > a{
	position: relative;
	display: block;
	line-height: 24px;
	padding: 10px 20px;
	font-size: 15px;
	color: #404040;
	text-transform: capitalize;
}

.mobile-menu .navigation li:hover > a,
.mobile-menu .navigation li.current > a{
	color:#000000;	
}

.mobile-menu .navigation li.dropdown .dropdown-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:44px;
	height:44px;
	text-align:center;
	font-size:16px;
	line-height:44px;
	color:#404040;
	cursor:pointer;
	z-index:5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn:after{
	content:'';
	position:absolute;
	left:0px;
	top:10px;
	width:1px;
	height:24px;
	border-left:1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul{
	display: none;
}

.sticky-header .navbar-header{
	display:none;
}

.main-menu .navbar-collapse {
    padding: 0px;
    float: left;
    display: block !important;
}

.sticky-header .logo{
	padding:0px 0px;
}

/***

====================================================================
	Hidden Sidebar style
====================================================================

***/

.hidden-bar{
	position: fixed;
	top: 70px;
	width: 180px;
	height: 100%;
	background: #ffffff;
	z-index:9999;
	transition: all 700ms ease;
	-webkit-transition: all 700ms ease;
	-ms-transition: all 700ms ease;
	-o-transition: all 700ms ease;
	-moz-transition: all 700ms ease;
}

.hidden-bar .hidden-bar-wrapper{
	max-height: 100%;
    overflow-y: auto;
    overflow-x: inherit;
}

/* width */

.hidden-bar .hidden-bar-wrapper::-webkit-scrollbar{
	width: 5px;
	cursor:pointer;
}

/* Track */

.hidden-bar .hidden-bar-wrapper::-webkit-scrollbar-track{
	background: #ffffff; 
	cursor:pointer;
}
 
/* Handle */

.hidden-bar .hidden-bar-wrapper::-webkit-scrollbar-thumb{
  background: #ff5773;
  cursor:pointer;
}

/* Handle on hover */

.hidden-bar .hidden-bar-wrapper::-webkit-scrollbar-thumb:hover{
  background: #ff5773; 
}

.hidden-bar .mCSB_inside > .mCSB_container{
	margin-right:0px;
}

.hidden-bar.left-align {
	left: -400px;
}

.hidden-bar.left-align.visible-sidebar {
	left: 0px;
}

.hidden-bar.left-align .color-layer{
	position: absolute;
    content: '';
    left: 100%;
    top: 0px;
    width: 100000%;
    height: 100%;
	opacity:0;
	visibility:hidden;
    display: block;
    background-color: rgba(0,0,0,0.75);
    transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
}

.hidden-bar.left-align.visible-sidebar .color-layer{
	opacity:1;
	visibility:visible;
}

.hidden-bar.left-align.visible-sidebar.alternate::before{
	display:none;
}

.hidden-bar .hidden-bar-closer {
	width: 40px;
	height: 40px;
	position: absolute;
	right:0px;
	top:0px;
	display:none;
	cursor:pointer;
	background: #ff5773;
	color: #ffffff;
	border-radius: 0px;
	text-align: center;
	line-height: 40px;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	z-index: 999999;
}

.hidden-bar .hidden-bar-closer button {
	background: none;
	font-size: 36px;
	color:#ffffff;
	width:40px;
	height:40px;
	cursor:pointer;
	display:none;
	line-height:40px;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.hidden-bar .hidden-bar-closer button span{
	position:relative;
	font-size:20px;
	display:inline-block;
}

.hidden-bar .hidden-bar-closer:hover button {
	color: #ffffff;
}

.hidden-bar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
	background-color:#ff5773;
}

.hidden-bar-wrapper {
	height: 100%;
	padding:0px 0px 30px 0px;
}

.hidden-bar .pages-list{
	position:relative;
}

.hidden-bar .pages-list li{
	position:relative;
}

.hidden-bar .pages-list li a{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:600;
	display:block;
	padding:9px 10px 9px 18px;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.hidden-bar .pages-list li a .icon{
	position:relative;
	color:#9fa4c2;
	font-size:16px;
	line-height:1em;
	font-weight:400;
	margin-right:10px;
}

.hidden-bar .pages-list li.active a,
.hidden-bar .pages-list li a:hover{
	color:#ff5773;
	background-color:#feeff2;
}

.hidden-bar .pages-list li:hover a .icon,
.hidden-bar .pages-list li.active a .icon{
	color:#ff5773;
}

.hidden-bar .heading{
	position:relative;
	font-size:14px;
	font-weight:600;
	color:#252841;
	padding:9px 10px 9px 18px;
}

.sidenav-list{
	position: fixed;
	left:0px;
	top: 70px;
    height: 100%;
    width:70px;
    z-index: 9999;
    background: #ffffff;
}

.sidenav-list ul{
	display: block;
    padding: 0;
	padding-top:15px;
}

.sidenav-list ul li{
	padding: 0;
    display: block;
	text-align:center;
}

.sidenav-list ul li a{
	padding: 0;
    color: #fff;
    display: block;
    position: relative;
	margin-bottom:15px;
}

.sidenav-list ul li a .icon{
	padding: 0;
	right: 0;
    width: 40px;
	color:#a7a9c5;
    height: 35px;
    text-align: center;
    font-size: 18px;
    line-height: 34px;
    position: relative;
	display:inline-block;
	background-color:#feeff2;
}

.sidenav-list ul li a span{
	position: absolute;
	top:0px;
	width:180px;
	height: 35px;
    line-height: 35px;
    padding: 0 40px 0 0px;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 600;
    display: block;
    left: 0;
	color:#ffffff;
    background: #ff5773;
    border-radius: 0px 6px 6px 0px;
    white-space: nowrap;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    transform: translateX(-200px);
    -moz-transform: translateX(-200px);
    -webkit-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    -o-transform: translateX(-200px);
    transform-origin: left;
    -moz-transform-origin: left;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    z-index: -1;
}

.sidenav-list ul li a span i{
	position:relative;
	font-style:normal;
	color:#ffffff;
	font-size:15px;
	margin-right:15px;
	font-weight:400;
}

.sidenav-list ul a.active span,
.sidenav-list ul a:hover span {
	z-index:1;
    transform: translateX(0px);
    -moz-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
}

/*** 

====================================================================
	Sidebar Page Container
====================================================================

***/

.sidebar-page-container{
	position:relative;
	padding:70px 0px 45px;
}

.sidebar-page-container .content-side,
.sidebar-page-container .sidebar-side{
	margin-bottom:40px;
}

.sidebar-widget{
	margin-bottom:30px;
}

.sidebar-widget .widget-content{
	position:relative;
	border-radius:5px;
	background-color:#ffffff;
	box-shadow:0px 0px 10px rgba(0,0,0,0.02);
}

.sidebar-widget .widget-content .content{
	position:relative;
	padding:15px 15px 5px;
}

.sidebar-widget.popular-posts .widget-content .content{
	padding:15px 20px 5px;
}

/*Sidebar Title*/

.sidebar-title{
    position: relative;
    padding:13px 20px 11px;
	display:block;
	margin-bottom:0px;
	border-bottom:1px solid #f5fbff;
}

.sidebar-title h6{
	position:relative;
    color: #252841;
    font-weight: 700;
    line-height: 1.2em;
    text-transform: capitalize;
}

/* Tags Widget */

.tags-widget ul{
	position:relative;
}

.tags-widget ul li{
	position:relative;
	margin-bottom:12px;
	margin-right:4px;
	display:inline-block;
}

.tags-widget ul li a{
	position:relative;
	color:#252841;
	font-size:14px;
	font-weight:600;
	padding:4px 12px;
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	box-shadow:0px 0px 10px rgba(0,0,0,0.02);
}

.tags-widget ul li a:hover{
	color:#ff5773;
	background-color:#feeff2;
}

.categories-widget .widget-content .content{
	padding:15px 20px 20px;
}

/* Categories Widget */

.categories-widget ul li{
	position:relative;
	margin-bottom:11px;
}

.categories-widget ul li a{
	position:relative;
	color:#252841;
	font-size:14px;
	font-weight:600;
	padding-left:32px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.categories-widget ul li a .icon{
	position:absolute;
	left:0px;
	top:-3px;
	width:24px;
	height:24px;
	font-size:14px;
	font-weight:400;
	line-height:24px;
	text-align:center;
	border-radius:50px;
	display:inline-block;
	background-color:#f5fbff;
}

.categories-widget ul li a:hover{
	color:#ff5773;
}

.categories-widget .all-category{
	position:relative;
	color:#ff5773;
	font-size:12px;
	font-weight:500;
	margin-top:8px;
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	border-bottom:1px solid #ff5773;
}

.categories-widget .all-category:hover{
	
}

/* Featured Widget */

.featured-widget .widget-content .content{
	padding:15px 20px 20px;
}

.featured-widget .owl-dots{
	display:none;
}

.featured-widget .owl-nav{
	position:absolute;
	right:0px;
	top:-50px;
}

.featured-widget .owl-nav .owl-prev,
.featured-widget .owl-nav .owl-next{
	position:relative;
	width:18px;
	height:18px;
	color:#929292;
	font-size:10px;
	line-height:15px;
	text-align:center;
	border-radius:50px;
	margin-left:6px;
	display:inline-block;
	border:1px solid #e8e8e8;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.featured-widget .owl-nav .owl-prev:hover,
.featured-widget .owl-nav .owl-next:hover{
	color:#ffffff;
	border-color:#ff5773;
	background-color:#ff5773;
}

/* Featured Block */

.featured-block{
	position:relative;
}

.featured-block .inner-box{
	position:relative;
	border-radius:4px;
	overflow:hidden;
}

.featured-block .inner-box .image{
	position:relative;
}

.featured-block .inner-box .image .overlay-link{
	position:absolute;
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	z-index:1;
	display:block;
}

.featured-block .inner-box .image .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	color:#ffffff;
	overflow:hidden;
	text-align:center;
	background-color:rgba(0,0,0,0.30);
	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.featured-block .inner-box .image .overlay-inner{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:table;
	vertical-align:middle;
	padding:0px 0px;
}

.featured-block .inner-box .image .overlay-inner .overlay-content{
	position:relative;
	display:table-cell;
	vertical-align:middle;
}

.featured-block .inner-box .image .overlay-inner .author-image{
	position:relative;
	width:34px;
	height:34px;
	margin:0 auto;
	overflow:hidden;
	border-radius:50px;
	border:2px solid #ffffff;
}

.featured-block .inner-box .image .overlay-inner .name{
	position:relative;
	color:#ffffff;
	font-size:9px;
	margin-top:6px;
	font-weight:600;
	line-height:1.3em;
}

/* Support Widget */

.support-widget .widget-content{
	position:relative;
	padding:25px 15px;
	border-radius:8px;
	text-align:center;
}

.support-widget .widget-content h5{
	position:relative;
	color:#252841;
	font-weight:700;
	line-height:1.3em;
}

.support-widget .widget-content .text{
	position:relative;
	color:#71718a;
	font-weight:400;
	line-height:2em;
	font-size:12px;
	margin-top:12px;
	margin-bottom:20px;
}

/*** 

====================================================================
	Featured Section
====================================================================

***/

.featured-section{
	position:relative;
	padding-bottom:50px;
}

/* Featured Block Two */

.feature-block-two{
	position:relative;
	margin-bottom:20px;
}

.feature-block-two .inner-box{
	position:relative;
	padding:22px 20px;
	border-radius:4px;
	min-height:189px;
	background-color:#ffffff;
	box-shadow:0px 0px 10px rgba(0,0,0,0.04);
}

.feature-block-two .inner-box .content{
	position:relative;
	padding-left:130px;
}

.feature-block-two .inner-box .content .image{
	position:absolute;
	left:0px;
	top:0px;
	width:110px;
	border-radius:4px;
	overflow:hidden;
	background-color:#ff5773;
}

.feature-block-two .inner-box .content .image img{
	position:relative;
	display:block;
	transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
}

.feature-block-two .inner-box:hover .content .image img{
	opacity:0.6;
	transform:scale(1.07,1.07);
}

.feature-block-two .inner-box h6{
	position:relative;
	line-height:1.7em;
	font-size:14px;
	font-weight:500;
}

.feature-block-two .inner-box h6 a{
	position:relative;
	color:#0f0e2d;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.feature-block-two .inner-box h6 a:hover{
	color:#ff5773;
}

.feature-block-two .inner-box .text{
	position:relative;
	color:#71718a;
	font-size:12px;
	line-height:1.9em;
	font-weight:500;
	margin-top:3px;
}

.feature-block-two .inner-box .author{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:500;
	margin-top:3px;
	margin-bottom:2px;
}

.feature-block-two .inner-box .author span{
	position:relative;
	color:#ff5773;
}

.feature-block-two .inner-box .rating{
	position:relative;
	color:#f9cf56;
	font-size:12px;
}

.feature-block-two .inner-box .rating .fa.dark{
	color:#adadad;
}

.feature-block-two .inner-box .price{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:700;
	font-family: 'Roboto', sans-serif;
}

.feature-block-two .inner-box .content .eps_dots{
    position: absolute;
    right: 0px;
	top:0px;
}

.feature-block-two .inner-box .content .eps_dots a{
	font-size: 20px;
	width:5px;
	height:20px;
	display:inline-block;
}

.feature-block-two .inner-box .content .eps_dots a span{
	position:absolute;
	right:0px;
	top:0px;
	width:20px;
	height:40px;
}

.feature-block-two .inner-box .content .eps_dots a i{
	position:absolute;
	content:'';
	left:0px;
	top:11px;
	width:5px;
	height:5px;
	display:block;
	border-radius:50px;
	background-color:#b2b2b2;
}

.feature-block-two .inner-box .content .eps_dots a:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:5px;
	height:5px;
	display:block;
	border-radius:50px;
	background-color:#b2b2b2;
}

.feature-block-two .inner-box .content .eps_dots a:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:5px;
	height:5px;
	display:block;
	border-radius:50px;
	background-color:#b2b2b2;
}

.feature-block-two .inner-box .content .more_dropdown {
  display: inline-block;
}

.feature-block-two .inner-box .content .dropdown-content {
	display: none;
	position: absolute;
	background-color: #fff;
	min-width: 160px;
	z-index: 1;
	right: 0;
	border-radius: 2px;
	box-shadow: 0px 0px 10px rgba(0,0,0, 0.10);
}

.feature-block-two .inner-box .content .dropdown-content span {
	color: #686f7a;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	padding: 10px;
	text-decoration: none;
	display: block;
	cursor: pointer;
}

.feature-block-two .inner-box .content .dropdown-content span i {
	margin-right: 10px;
	font-size: 100%;
	font-weight:600;
}

.feature-block-two .inner-box .content .dropdown-content span:hover {
	color: #333333 !important;
	background-color: #fff0f3;
}

.feature-block-two .inner-box .content .more_dropdown:hover .dropdown-content {
	display: block;
}

.feature-block-two .inner-box .content .more_dropdown:hover .eps_dots {
	background-color: #333;
}

/*** 

====================================================================
	Courses Section
====================================================================

***/

.courses-section{
	position:relative;
	padding-bottom:40px;
}

.course-block{
	position:relative;
	padding-bottom:30px;
}

.course-block .inner-box{
	position:relative;
	border-radius:5px;
	padding:10px 10px 18px;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.course-block .inner-box:hover{
	box-shadow:0px 10px 10px rgba(0,0,0,0.04);
}

.course-block .inner-box .image{
	position:relative;
	background-color:#ff5773;
}

.course-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
	-webkit-transition:all 600ms ease;
	-moz-transition:all 600ms ease;
	-ms-transition:all 600ms ease;
	-o-transition:all 600ms ease;
	transition:all 600ms ease;
}

.course-block .inner-box:hover .image img{
	opacity:0.5;
}

.course-block .inner-box .image .time{
	position:absolute;
	right:10px;
	bottom:10px;
	color:#0f0e2d;
	font-size:12px;
	font-weight:400;
	padding:5px 14px;
	border-radius:50px;
	display:inline-block;
	background-color:#f5fbff;
	font-family: 'Roboto', sans-serif;
}

.course-block .inner-box .lower-content{
	position:relative;
	padding-top:20px;
}

.course-block .inner-box .lower-content h6{
	position:relative;
	font-weight:500;
	font-size:14px;
}

.course-block .inner-box .lower-content h6 a{
	position:relative;
	color:#0f0e2d;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.course-block .inner-box .lower-content h6 a:hover{
	color:#ff5773;
}

.course-block .inner-box .lower-content .post-meta{
	position:relative;
	margin-top:7px;
	margin-bottom: 8px;
}

.course-block .inner-box .lower-content .post-meta li{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:500;
	line-height:1.2em;
	padding-right:12px;
	margin-right:10px;
	display:inline-block;
	border-right:1px solid #71718a;
}

.course-block .inner-box .lower-content .post-meta li:last-child{
	border-right:0px;
	padding-right:0px;
	margin-right:0px;
}

.course-block .inner-box .lower-content .author{
	position: relative;
    color: #71718a;
    font-size: 12px;
    font-weight: 600;
}

.course-block .inner-box .lower-content .author span{
	position: relative;
    color: #ff5773;
}

.course-block .inner-box .lower-content .price{
	position: relative;
    color: #0f0e2d;
	font-size:14px;
	font-weight:700;
	font-family: 'Roboto', sans-serif;
}

/*** 

====================================================================
	Team Section
====================================================================

***/

.team-section{
	position:relative;
	padding-bottom:30px;
}

.team-section .row{
	margin:0px -9px;
}

.team-section .team-block{
	padding:0px 9px;
}

.team-block{
	position:relative;
	margin-bottom:30px;
}

.team-block .inner-box{
	position:relative;
	border-radius:5px;
	text-align:center;
	padding:30px 15px 30px;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.team-block .inner-box:hover{
	box-shadow:0px 5px 20px rgba(0,0,0,0.07);
}

.team-block .inner-box .image{
	position:relative;
	width:70px;
	height:70px;
	margin:0 auto;
	overflow:hidden;
	border-radius:50%;
	margin-bottom:20px;
}

.team-block .inner-box .name{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:500;
	font-family: 'Roboto', sans-serif;
}

.team-block .inner-box .name a{
	position:relative;
	color:#0f0e2d;
}

.team-block .inner-box .designation{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:500;
	margin-top:3px;
	margin-bottom:0px;
}

.team-block .inner-box .text{
	position:relative;
	margin-top:8px;
	color: #71718a;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
}

.team-block .inner-box .courses{
	position:relative;
	color:#0f0e2d;
	font-size:12px;
	font-weight:500;
	margin-top:8px;
	margin-bottom:15px;
}

.team-block .inner-box .social-box{
	position:relative;
}

.team-block .inner-box .social-box li{
	position:relative;
	margin:0px 4px;
	display:inline-block;
}

.team-block .inner-box .social-box li a{
	position:relative;
	width:30px;
	height:30px;
	color:#3b5998;
	font-size:13px;
	line-height:30px;
	border-radius:5px;
	background-color:#eff4ff;
}

.team-block .inner-box .social-box li.google a{
	background-color:#ffeded;
	color:#db3236;
}

.team-block .inner-box .social-box li.twitter a{
	background-color:#ecfaff;
	color:#00acee;
}

/*** 

====================================================================
	Testimonial Section
====================================================================

***/

.testimonial-section{
	position:relative;
}

.testimonial-section.style-two{
	padding:65px 0px 60px;
}

.testimonial-section .owl-dots{
	display:none;
}

.testimonial-section .owl-nav{
	position:absolute;
	right:0px;
	top:-40px;
}

.testimonial-section .owl-nav .owl-prev,
.testimonial-section .owl-nav .owl-next{
	position:relative;
	width:18px;
	height:18px;
	color:#929292;
	font-size:10px;
	line-height:15px;
	text-align:center;
	border-radius:50px;
	margin-left:6px;
	display:inline-block;
	border:1px solid #e8e8e8;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.testimonial-section .owl-nav .owl-prev:hover,
.testimonial-section .owl-nav .owl-next:hover{
	color:#ffffff;
	border-color:#ff5773;
	background-color:#ff5773;
}

.testimonial-block{
	position:relative;
	margin-bottom:30px;
}

.testimonial-block .inner-box{
	position:relative;
	border-radius:5px;
	text-align:center;
	padding:30px 20px 30px;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.testimonial-block .inner-box .quote{
	position:absolute;
	left:30px;
	top:35px;
	color:#f5fbff;
	font-size:50px;
	line-height:1em;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.testimonial-block .inner-box:hover .quote{
	color:#ff5773;
}

.testimonial-block .inner-box .image{
	position:relative;
	width:70px;
	height:70px;
	margin:0 auto;
	overflow:hidden;
	border-radius:50%;
	margin-bottom:15px;
}

.testimonial-block .inner-box .name{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:500;
	font-family: 'Roboto', sans-serif;
}

.testimonial-block .inner-box .designation{
	position:relative;
	color:#ff5773;
	font-size:12px;
	font-weight:500;
	margin-top:1px;
	margin-bottom:0px;
	font-family: 'Roboto', sans-serif;
}

.testimonial-block .inner-box .text{
	position:relative;
	color:#71718a;
	font-size:13px;
	font-weight:500;
	margin-top:8px;
	line-height:1.9em;
}

.sticky-top{
	top:90px;
}

/*** 

====================================================================
	Main Footer
====================================================================

***/

.main-footer{
	position:relative;
	overflow:hidden;
	background-color:#252841;
}

.main-footer .pattern-layer{
	position:absolute;
	left:100px;
	top:100px;
	width:271px;
	height:116px;
}

.main-footer .pattern-layer-two{
	position:absolute;
	right:-280px;
	top:120px;
	width:451px;
	height:226px;
}

.main-footer .upper-box{
	position:relative;
	z-index:1;
	padding-top:100px;
	padding-bottom:20px;
}

.main-footer .upper-box h4{
	position:relative;
	color:#ffffff;
	font-size:24px;
	line-height:1.4em;
	font-weight:700;
	margin-bottom:40px;
	text-align:center;
}

.main-footer .upper-box .subscribe-form{
	max-width:560px;
	margin:0 auto;
}

/* Subscribe Form */

.subscribe-form{
	position:relative;
	width:100%;
	margin-bottom:0px;
}

.subscribe-form .form-group{
    position: relative;
    display: block;
    z-index:1;
	margin-bottom: 0px;
}

.subscribe-form .form-group input[type="text"],
.subscribe-form .form-group input[type="tel"],
.subscribe-form .form-group input[type="email"],
.subscribe-form .form-group textarea {
    position:relative;
	display:block;
	width:100%;
	height:52px;
	font-size:15px;
	color:#ffffff;
	line-height:38px;
	padding:10px 20px 10px 30px;
	font-weight:400;
	border-radius:50px;
	background:none;
	border:1px solid #65698a;
	transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;	
}

.subscribe-form .form-group input[type="email"]:focus{
	border-color:#ffffff;
}

.subscribe-form .form-group textarea::-webkit-input-placeholder,
.subscribe-form .form-group input::-webkit-input-placeholder{
	color:#ffffff;
}

.subscribe-form .form-group .theme-btn{
	position: absolute;
	top: 0px;
	right: 0px;
	cursor:pointer;
	padding:11px 50px;
}

.main-footer .widgets-section{
	position:relative;
	padding:60px 0px 40px;
}

.main-footer .footer-widget{
	position:relative;
	z-index:2;
	margin-bottom:30px;
}

.main-footer .logo-widget{
	position:relative;
	z-index:3;
}

.main-footer .logo-widget .logo{
	position:relative;
	opacity:1;
	visibility:visible;
	margin-bottom:18px;
}

.main-footer .logo-widget .text{
	position:relative;
	font-size:15px;
	color:#ffffff;
	line-height:1.9em;
	margin-bottom:25px;
}

.main-footer .logo-widget .social-box{
	position:relative;
}

.main-footer .logo-widget .social-box a{
	position:relative;
	font-size:28px;
	color:#ffffff;
	margin-right:10px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-footer .logo-widget .social-box a:hover{
	color:#ff5773;
}

.main-footer .links-widget li{
	position:relative;
	margin-bottom:10px;
}

.main-footer .links-widget li a{
	position:relative;
	color:#eeeeee;
	font-size:14px;
	font-weight:400;
	text-transform:capitalize;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-footer .links-widget li a:hover{
	color:#ff5773;
}

.main-footer .footer-bottom{
	position:relative;
	padding:20px 0px;
	text-align:center;
	border-top:1px solid rgba(255,255,255,0.10);
}

.main-footer .footer-bottom .copyright{
	position:relative;
	color:#dcdddd;
	font-size:14px;
	font-weight:500;
}

/*** 

====================================================================
	Styled Pagination
====================================================================

***/

.styled-pagination{
	position:relative;
}

.styled-pagination.margin-top{
	margin-top:10px;
}

.styled-pagination.margin-top-two{
	margin-top:30px;
}

.styled-pagination li{
	position:relative;
	margin:0px 4px 5px;
	display:inline-block;
}

.styled-pagination li a{
	position:relative;
	width:40px;
	height:40px;
	color:#71718a;
	font-size:14px;
	font-weight:500;
	line-height:40px;
	text-align:center;
	border-radius:50px;
	display:inline-block;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.styled-pagination li.selected a,
.styled-pagination li:hover a{
	color:#ffffff;
	background-color:#ff5773;
}

.styled-pagination li.previous a,
.styled-pagination li.next a{
	width:auto;
	height:auto;
	color:#71718a;
	font-size:14px;
	background:none !important;
	margin:0px 3px;
}
.styled-pagination li.previous.disabled a,
.styled-pagination li.next.disabled a{
	cursor: not-allowed;
	opacity: .5;
}
.styled-pagination li.arrow a{
	position:relative;
	font-size:24px;
}

/* Review Widget */

.review-widget .intro-video{
	position:relative;
	text-align:center;
	width:100%;
	display:block;
	overflow:hidden;
	border-radius:6px;
	padding:40px 0px 30px;
	background-repeat:no-repeat;
	background-size:cover;
}

.review-widget .intro-video:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	background-color:rgba(14,123,254,0.50);
}

.review-widget .intro-video h4{
	position:relative;
	color:#ffffff;
	font-weight:600;
	font-size:12px;
	line-height:1.3em;
	margin-top:20px;
	text-transform:capitalize;
}

.review-widget .intro-video .intro-video-box{
	position: relative;
	width:40px;
	height: 40px;
	z-index:99;
	color: #ff5773;
	font-weight:400;
	font-size:14px;
	text-align: center;
	border-radius:50%;
	padding-left:4px;
	line-height:40px;
	display: inline-block;
	background-color:#ffffff;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	box-shadow:0px 0px 15px rgba(0,0,0,0.10);
}

.review-widget .intro-video .ripple,
.review-widget .intro-video .ripple:before,
.review-widget .intro-video .ripple:after {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    width: 40px;
	margin-left:-1px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
}

.review-widget .intro-video .ripple:before {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
}

.review-widget .intro-video .ripple:after {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
    position: absolute;
}

@-webkit-keyframes ripple {
    70% {box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);}
    100% {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}

@keyframes ripple {
    70% {box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);}
    100% {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}

.review-widget .price{
	position:relative;
	display:block;
	margin-top:25px;
	font-weight:700;
	color:#0f0e2d;
	font-size:18px;
	text-align:center;
}

.review-widget .time-left{
	position:relative;
	color:#71718a;
	font-size:12px;
	margin-top:10px;
	margin-bottom:20px;
	text-align:center;
	font-weight:500;
}

.review-widget .buttons-box .theme-btn{
	width:100%;
	margin-bottom:10px;
}

.review-widget .buttons-box{
	position:relative;
	text-align:center;
	padding:0px 20px;
}

/* Post Widget */

.popular-posts .post{
	position:relative;
	min-height:30px;
	margin-bottom:5px;
}

.popular-posts .post:last-child{
	margin-bottom:0px;
}

.popular-posts .post .post-inner{
	position:relative;
	padding-left:55px;
}

.popular-posts .post .post-thumb{
	 position:absolute;
	 left:0px;
	 top:4px;
	 width:45px;
	 height:33px;
	 margin:0px;
	 box-shadow:0px 0px 10px rgba(0,0,0,0.07);
}

.popular-posts .post .post-thumb img{
	display:block;
	width:100%;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.popular-posts .post .text{
	position:relative;
	top:0px;
	font-size:14px;
	margin:0px 0px 0px;
	font-weight:600;
	color:#252841;
	line-height:1.5em;
	text-transform:capitalize;
}

.popular-posts .post .text a{
	color:#252841;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.popular-posts .post a:hover{
	color:#ff5773;
}

.popular-posts .timing{
	position:relative;
	font-size:10px;
	color:#71718a;
	font-weight:500;
}

/* Post Widget */

.recent-posts .post{
	position:relative;
	min-height:40px;
	padding-bottom:8px;
	margin-bottom:12px;
	border-bottom:1px solid #f5fbff;
}

.recent-posts .post:last-child{
	min-height:auto;
	margin-bottom:0px;
	border-bottom:none;
}

.recent-posts .post .post-inner{
	position:relative;
	padding-left:55px;
}

.recent-posts .post .post-thumb{
	 position:absolute;
	 left:0px;
	 top:4px;
	 width:45px;
	 height:33px;
	 margin:0px;
	 background-color:#ff5773;
	 box-shadow:0px 0px 10px rgba(0,0,0,0.07);
}

.recent-posts .post .post-thumb img{
	display:block;
	width:100%;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.recent-posts .post:hover .post-thumb img{
	opacity:0.5;
}

.recent-posts .post .text{
	position:relative;
	top:0px;
	font-size:14px;
	margin:0px 0px 0px;
	font-weight:600;
	color:#252841;
	line-height:1.4em;
	text-transform:capitalize;
}

.recent-posts .post .text a{
	color:#252841;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.recent-posts .post a:hover{
	color:#ff5773;
}

.recent-posts .author{
	position:relative;
	font-size:10px;
	color:#71718a;
	font-weight:500;
}

/* Video Box */

.video-box{
	position:relative;
	overflow:hidden;
	border-radius:3px;
	box-shadow:0px 0px 10px rgba(0,0,0,0.05);
}

.video-box .video-image{
	position:relative;
	margin:0px;
}

.video-box .video-image img{
	position:relative;
	width:100%;
	z-index:3;
}

.video-box .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	text-align:center;
	overflow:hidden;
	line-height: 45px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.video-box .overlay-box:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	z-index:10;
	background-color:rgba(0,0,0,0.02);
}

.video-box .overlay-box span{
	position: absolute;
	width:80px;
	height: 80px;
	left:50%;
    top: 50%;
	z-index:10;
	color: #ffffff;
	font-weight:400;
	font-size:20px;
	text-align: center;
	border-radius:50%;
	padding-left:4px;
	display: inline-block;
	margin-top: -40px;
	margin-left:-40px;
	line-height:80px;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	background-color:#ff5773;
}

.video-box .ripple,
.video-box .ripple:before,
.video-box .ripple:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width:80px;
	height: 80px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 87, 115, .6);
    -moz-box-shadow: 0 0 0 0 rgba(255, 87, 115, .6);
    -ms-box-shadow: 0 0 0 0 rgba(255, 87, 115, .6);
    -o-box-shadow: 0 0 0 0 rgba(255, 87, 115, .6);
    box-shadow: 0 0 0 0 rgba(255, 87, 115, .6);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
}

.video-box .ripple:before {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
}

.video-box .ripple:after {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
    position: absolute;
}

.video-info-boxed{
	position:relative;
	padding-top:25px;
	margin-bottom:25px;
}

.video-info-boxed h6{
	position:relative;
	color:#0f0e2d;
	font-weight:500;
	line-height:1.3em;
	margin-bottom:10px;
}

.video-info-boxed .author{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
	padding-top:2px;
	padding-left:35px;
	display:inline-block;
}

.video-info-boxed .author .user-image{
	position:absolute;
	left:0px;
	top:0px;
	width:26px;
	height:26px;
	overflow:hidden;
	display:inline-block;
	border-radius:50px;
	border:2px solid #ffffff;
}

.video-info-boxed .follow{
	position:relative;
	margin-left:16px;
	top:1px;
	display:inline-block;
}

.video-info-boxed .follow a{
	position:relative;
	color:#0e7bfe;
	font-size:14px;
	font-weight:600;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.video-info-boxed .follow a:hover{
	color:#ff5773;
}

.video-info-boxed .social-box{
	position:relative;
}

.video-info-boxed .social-box .share{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
	display:block;
	margin-bottom:10px;
}

.video-info-boxed .social-box li{
	position:relative;
	margin:0px 4px;
	display:inline-block;
}

.video-info-boxed .social-box li a{
	position:relative;
	width:30px;
	height:30px;
	color:#3b5998;
	font-size:13px;
	text-align:center;
	line-height:30px;
	border-radius:5px;
	background-color:#eff4ff;
}

.video-info-boxed .social-box li.google a{
	background-color:#ffeded;
	color:#db3236;
}

.video-info-boxed .social-box li.twitter a{
	background-color:#ecfaff;
	color:#00acee;
}

/*** 

====================================================================
	Intro Tabs Style
====================================================================

***/

.course-tabs{
	position:relative;	
	background-color:#ffffff;
}

.course-tabs .tab-btns{
	position:relative;
	padding-left:10px;
	border-bottom:1px solid #e5e5e5;
}

.course-tabs .tab-btns .tab-btn{
	position:relative;
	display:block;
	font-size:14px;
	background:none;
	color:#71718a;
	font-weight:500;
	line-height:24px;
	cursor:pointer;
	float:left;
	margin-right:15px;
	background-color:#ffffff;
	padding:12px 15px 10px;
	text-transform:capitalize;
	transition:all 500ms ease;
	font-family: 'Roboto', sans-serif;
}

.course-tabs .tab-btns .tab-btn:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:-1px;
	height:1px;
	width:100%;
	opacity:0;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:#ff5773;
}

.course-tabs .tab-btns .tab-btn.active-btn::before,
.course-tabs .tab-btns .tab-btn:hover::before{
	opacity:1;
}

.course-tabs .tab-btns .tab-btn:hover,
.course-tabs .tab-btns .tab-btn.active-btn{
	color:#0f0e2d;
}

.course-tabs .tabs-content{
	position:relative;
	overflow:hidden;
	background-color:#ffffff;
}

.course-tabs .tabs-content .tab{
	position:relative;
	display:none;
	padding-bottom:20px;
}

.course-tabs .tabs-content .tab.active-tab{
	display:block;	
}

/*** 

====================================================================
	Accordions style
====================================================================

***/

.accordion-box{
	position:relative;
}

.accordion-box .block{
	position:relative;
	background-color:#ffffff;
}

.accordion-box .block .acc-btn{
	position:relative;
	font-size:14px;
	margin-bottom:0px;
	cursor:pointer;
	line-height:24px;
	font-weight:600;
    color:#0f0e2d;
	padding: 10px 40px 10px 25px;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Roboto', sans-serif;
}

.accordion-box .block .acc-btn .icon-inner{
	color:#13b5ea;
	font-size:16px;
}

.accordion-box .block .icon-outer{
	position:absolute;
	right:50px;
	top:0px;
	height:100%;
	text-align:left;
	font-size:18px;
	color:#616161;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.accordion-box .block .icon-outer .icon{
	position:absolute;
	left:0px;
	top:50%;
	margin-top:-21px;
	width:100%;
	height:42px;
	line-height:42px;
	font-size:13px;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;	
}

.accordion-box .block .acc-btn.active .icon-outer{
	
}

.accordion-box .block .acc-btn.active .icon-outer .icon-plus{
	transform:rotate(180deg);
	left:12px;
}

.accordion-box .block .acc-content{
	position:relative;
	display:none;
}

.accordion-box .block .acc-content.current{
	display:block;	
}

.accordion-box .block .content{
	position:relative;
	padding:5px 30px 10px 45px;
}

.accordion-box .block .content .minutes{
	position:relative;
	color:#ff5773;
	font-size:15px;
	font-weight:500;
	margin-top:8px;
}

.accordion-box .block .play-icon{
	position:relative;
	color:#71718a;
	font-size:15px;
	text-align:center;
	font-weight:500;
	line-height: 40px;
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	/* background-color:#ffffff; */
}

.accordion-box .block .play-icon .fa{
	position: relative;
	width:40px;
	height: 40px;
	left:0px;
	z-index:99;
	color: #ff5773;
	font-weight:400;
	font-size:16px;
	text-align: center;
	border-radius:50%;
	padding-left:4px;
	margin-right:20px;
	display: inline-block;
	margin-top: -20px;
	margin-left:-20px;
	background-color:#ffffff;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	box-shadow:0px 0px 15px rgba(0,0,0,0.10);
}

.accordion-box .block .play-icon .ripple,
.accordion-box .block .play-icon .ripple:before,
.accordion-box .block .play-icon .ripple:after {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    width: 40px;
	margin-left:.5px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 87, 115, .4);
    -moz-box-shadow: 0 0 0 0 rgba(255, 87, 115, .4);
    -ms-box-shadow: 0 0 0 0 rgba(255, 87, 115, .4);
    -o-box-shadow: 0 0 0 0 rgba(255, 87, 115, .4);
    box-shadow: 0 0 0 0 rgba(255, 87, 115, .4);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
}

.accordion-box .block .play-icon .ripple:before {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
}

.accordion-box .block .play-icon .ripple:after {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
    position: absolute;
}

.accordion-box.style-two .block{
	border-radius:5px;
	margin-bottom:20px;
	background-color:#f5fbff;
}

.accordion-box.style-two .block .acc-btn{
	font-size:14px;
	color:#71718a;
	font-weight:500;
}

.accordion-box.style-two .block .acc-content{
	padding:10px 0px 20px;
}

.accordion-box.style-two .block .icon-outer{
	right:35px;
}

.accordion-box.style-two .block .content{
	padding:0px 25px 0px 38px;
	margin-bottom:5px;
}

.accordion-box.style-two .block .content .text-link{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:300;
}

.accordion-box.style-two .block .content .text-link:before{
	position:absolute;
	content:'';
	left:-10px;
	top:5px;
	width:4px;
	height:4px;
	display:inline-block;
	border-radius:50px;
	background-color:#fe6680;
}

.accordion-box.style-two .block .content .minutes{
	color:#71718a;
	font-size:12px;
	margin-top:2px;
}

/* Student Review Box */

.student-review-box{
	position:relative;
	padding:25px 28px;
}

.student-review-box h6{
	position:relative;
	color:#0f0e2d;
	font-weight:500;
	line-height:1.3em;
	margin-bottom:12px;
	font-family: 'Roboto', sans-serif;
}

.student-review-box .inner-student-review-box{
	position:relative;
	padding:0px 10px 0px 10px;
}

.student-review-box .rating-column{
	position:relative;
}

.student-review-box .rating-column .inner-column{
	position:relative;
	text-align:center;
	padding-top:25px;
}

.student-review-box .rating-column .inner-column .total-rating{
	position:relative;
	color:#ff5773;
	font-size:26px;
	font-weight:600;
	margin-bottom:10px;
}

.student-review-box .rating-column .inner-column .rating{
	position:relative;
	color:#ff5773;
	font-size:14px;
}

.student-review-box .rating-column .inner-column .rating .fa.light{
	position:relative;
	color:#0e7bfe;
}

.student-review-box .rating-column .inner-column .title{
	position:relative;
	color:#71718a;
	font-size:13px;
	font-weight:600;
	margin-top:4px;
}

.student-review-box .stars-column{
	position:relative;
}

.student-review-box .stars-column .rating{
	position:relative;
	color:#ff5773;
	line-height:1em;
	margin-top:-4px;
	margin-left:-4px;
	margin-right:-4px;
	margin-bottom:17px;
}

.student-review-box .stars-column .rating .fa{
	line-height:1em;
	font-size:12px;
	display:inline-block;
}

.student-review-box .stars-column .rating .fa.light{
	position:relative;
	color:#0e7bfe;
}

.student-review-box .stars-column .rating i{
	position:relative;
	font-style:normal;
	color:#71718a;
	font-size:12px;
	font-style:normal;
	font-weight:600;
	margin-left:2px;
	display:inline-block;
}

/* Skills Section */

.skills .skill-item{
	position:relative;
	margin-bottom:20px;
}

.skills .skill-item:last-child{
	margin-bottom:0px;
}

.skills .skill-item .skill-bar{
	position:relative;
	width:100%;
}

.skills .skill-item .skill-bar .bar-inner{
	position:relative;
	width:100%;
	overflow:hidden;
	height:8px;
	border-radius:2px;
	background:#ededed;
}

.skills .skill-item .skill-bar .bar-inner .bar{
	position:absolute;
	left:0px;
	top:0px;
	height:8px;
	width:0px;
	background-color:#dbd9da;
	-webkit-transition:all 2000ms ease;
	-ms-transition:all 2000ms ease;
	-o-transition:all 2000ms ease;
	-moz-transition:all 2000ms ease;
	transition:all 2000ms ease;
}

.skills .skill-item .skill-header{
	position:relative;
	display:none;
	margin-bottom:12px;
}

.skills .skill-item .skill-header .skill-title{
	float:left;
	color:#19274d;
	font-size:18px;
	font-weight:600;
	line-height:1.4em;
	text-transform:capitalize;
}

.skills .skill-item .skill-header .skill-percentage{
	position:relative;
	float:right;
	font-weight:600;
	font-size:16px;
	color: #ff5773;
	text-transform:uppercase;
}

/* Review Comments Box */

.review-comments-box{
	position:relative;
}

.review-comments-box h6{
	position: relative;
    color: #0f0e2d;
    font-weight: 500;
    line-height: 1.3em;
	margin-bottom:25px;
	padding:0px 30px 15px;
	border-bottom:1px solid #ededed;
}

/* Reviewer Comment Box */

.reviewer-comment-box{
	position:relative;
	padding:0px 30px;
	margin-bottom:30px;
}

.reviewer-comment-box h4{
	position:relative;
	color:#0f0e2d;
	font-weight:500;
	font-size:16px;
	line-height:1.3em;
	margin-bottom:15px;
}

.reviewer-comment-box .rating{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
}

.reviewer-comment-box .rating .total-rating{
	position:relative;
	color:#ff5773;
	font-size:18px;
	font-weight:600;
	margin-right:5px;
}

.reviewer-comment-box .rating .fa{
	position:relative;
	color:#ff5773;
	margin:0px 2px;
	font-size:16px;
}

.reviewer-comment-box .rating .fa.light{
	color:#0e7bfe;
}

.reviewer-comment-box .text{
	position:relative;
	color:#71718a;
	font-size:14px;
	line-height:1.8em;
	margin-top:15px;
	font-weight:500;
}

.reviewer-comment-box .helpful{
	position:relative;
	color:#0f0e2d;
	line-height:1.8em;
	margin-top:10px;
	font-weight:500;
	margin-bottom:15px;
	font-family: 'Roboto', sans-serif;
}

.reviewer-comment-box .like-option{
	position:relative;
}

.reviewer-comment-box .like-option li{
	position:relative;
	margin-right:10px;
	display:inline-block;
}

.reviewer-comment-box .like-option li span{
	position:relative;
	width:36px;
	height:36px;
	color:#71718a;
	font-size:18px;
	cursor:pointer;
	text-align:center;
	line-height:36px;
	border-radius:50px;
	display:inline-block;
	background-color:#ffffff;
	box-shadow:0px 10px 10px rgba(0,0,0,0.10);
}

.reviewer-comment-box .like-option li span:hover{
	color:#ff5773;
}

.reviewer-comment-box .like-option .report{
	position:relative;
	color:#ff5773;
	font-size:14px;
	font-weight:600;
}

.reviewer-comment-box .more{
	position:relative;
	color:#ff5773;
	font-size:14px;
	font-weight:600;
	margin-top:30px;
	display:inline-block;
}

/* Class Detail Content */

.class-detail-content{
	position:relative;
	padding:25px 25px;
}

.class-detail-content h4{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:500;
	line-height:1.3em;
	margin-bottom:10px;
}

.class-detail-content .text{
	position:relative;
	color:#71718a;
	font-size:12px;
	line-height:2em;
	font-weight:500;
	margin-bottom:20px;
}

.class-detail-content h6{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:500;
	line-height:1.3em;
	margin-bottom:18px;
}

/*** 

====================================================================
	Category Page Section
====================================================================

***/

.category-page-container{
	position:relative;
	padding:60px 0px 0px;
}

.category-page-container.style-two{
	padding-bottom:60px;
}

.category-page-container .owl-dots{
	display:none;
}

.category-page-container .owl-nav{
	position:absolute;
	right:0px;
	top:-45px;
}

.category-page-container .owl-nav .owl-prev,
.category-page-container .owl-nav .owl-next{
	position:relative;
	width:18px;
	height:18px;
	color:#929292;
	font-size:10px;
	line-height:15px;
	text-align:center;
	border-radius:50px;
	margin-left:6px;
	display:inline-block;
	border:1px solid #e8e8e8;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.category-page-container .owl-nav .owl-prev:hover,
.category-page-container .owl-nav .owl-next:hover{
	color:#ffffff;
	border-color:#ff5773;
	background-color:#ff5773;
}

/*** 

====================================================================
	Featured Section Two
====================================================================

***/

.featured-section-two{
	position:relative;
	padding:65px 0px 10px;
	background-color:#eef0f9;
}

/* Featured Block Three */

.feature-block-three{
	position:relative;
	padding-bottom:30px;
}

.feature-block-three .inner-box{
	position:relative;
	padding-left:60px;
}

.feature-block-three .inner-box .icon{
	position:absolute;
	left:0px;
	top:0px;
	width:40px;
	height:40px;
	color:#0e7bfe;
	font-size:20px;
	border-radius:3px;
	line-height:40px;
	line-height:40px;
	text-align:center;
	display:inline-block;
	background-color:#f5fbff;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.feature-block-three .inner-box:hover .icon{
	-webkit-transform: scale(-1) rotate(180deg);
    -moz-transform: scale(-1) rotate(180deg);
    -ms-transform: scale(-1) rotate(180deg);
    -o-transform: scale(-1) rotate(180deg);
    transform: scale(-1) rotate(180deg);
	background-color:#ff5773;
	color:#ffffff;
}

.feature-block-three .inner-box h5{
	position:relative;
	color:#0f0e2d;
	font-size:18px;
	font-weight:500;
	line-height:1.3em;
}

.feature-block-three .inner-box .text{
	position:relative;
	color:#71718a;
	font-size:12px;
	line-height:2em;
	font-weight:500;
	margin-top:8px;
}

/*** 

====================================================================
	Instructor Page Section
====================================================================

***/

.instructor-page-section{
	position:relative;
	padding:65px 0px 65px;
}

.instructor-page-section .upper-content{
	position:relative;
	border-radius:4px;
	padding:50px 50px 25px;
	background-color:#ffffff;
}

.instructor-page-section .upper-content .content{
	position:relative;
	padding-left:110px;
}

.instructor-page-section .upper-content .content .author-image{
	position:absolute;
	left:0px;
	top:0px;
	width:87px;
	height:87px;
	overflow:hidden;
	border-radius:50px;
}

.instructor-page-section .upper-content .content h4{
	position:relative;
	color:#0f0e2d;
	font-size:24px;
	font-weight:500;
	line-height:1.3em;
}

.instructor-page-section .upper-content .content .designation{
	position:relative;
	color:#0e7bfe;
	font-size:14px;
	font-weight:500;
	margin-top:6px;
	margin-bottom:12px;
}

.instructor-page-section .upper-content .content .social-box{
	position:relative;
}

.instructor-page-section .upper-content .content .social-box li{
	position:relative;
	margin-right:8px;
	display:inline-block;
}

.instructor-page-section .upper-content .content .social-box li a{
	position:relative;
	width:30px;
	height:30px;
	color:#3b5998;
	font-size:13px;
	text-align:center;
	line-height:30px;
	border-radius:5px;
	background-color:#eff4ff;
}

.instructor-page-section .upper-content .content .social-box li.google a{
	background-color:#ffeded;
	color:#db3236;
}

.instructor-page-section .upper-content .content .social-box li.twitter a{
	background-color:#ecfaff;
	color:#00acee;
}

.instructor-page-section .upper-content .content .fact-counter{
	margin-top:30px;
}

.instructor-page-section .upper-content .content .fact-counter .row{
	margin:0px -1px;
}

.instructor-page-section .upper-content .content .fact-counter .counter-column{
	padding:0px 1px;
}

/* Fact Counter */

.fact-counter{
	position:relative;
}

.fact-counter .counter-column{
	position:relative;
	margin-bottom:30px;
	text-align:center;
}

.fact-counter .counter-column .inner{
	position:relative;
	padding:12px 10px 15px;
	background-color:#eef0f9;
}

.fact-counter .counter-column .count-box{
	position:relative;
	text-align:center;
	font-weight:600;
	color:#0f0e2d;
	font-size:24px;
	line-height:1em;
}

.fact-counter .counter-column .count-text{
	display:inline-block;
	font-weight:600;
	color:#0f0e2d;
	font-size:24px;
	line-height:1em;
}

.fact-counter .counter-column .count-outer{
	
}

.fact-counter .counter-column .counter-title{
	position:relative;
	font-size:16px;
	font-weight:500;
	color:#0f0e2d;
	margin-bottom:6px;
}

.instructor-page-section .upper-content .right-column{
	position:relative;
}

.instructor-page-section .upper-content .right-column .buttons-box{
	position:relative;
	text-align:center;
	padding-top:15px;
}

.instructor-page-section .upper-content .right-column .buttons-box .theme-btn{
	position:relative;
	width:100%;
	max-width:200px;
	margin-bottom:5px;
}

.instructor-page-section .upper-content .right-column .buttons-box .theme-btn i{
	position:relative;
	margin-right:8px;
	font-style:normal;
}

.instructor-page-section .lower-content{
	position:relative;
	margin-top:1px;
}

/*** 

====================================================================
	Intro Tabs Style
====================================================================

***/

.instructor-tabs{
	position:relative;
}

.instructor-tabs .tab-btns{
	position:relative;
	padding-left:10px;
	background-color:#ffffff;
}

.instructor-tabs .tab-btns .tab-btn{
	position:relative;
	display:block;
	font-size:14px;
	background:none;
	color:#71718a;
	font-weight:500;
	line-height:24px;
	cursor:pointer;
	float:left;
	margin-right:15px;
	background-color:#ffffff;
	padding:10px 15px 8px;
	text-transform:capitalize;
	transition:all 500ms ease;
	font-family: 'Roboto', sans-serif;
}

.instructor-tabs .tab-btns .tab-btn:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:-1px;
	height:1px;
	width:100%;
	opacity:0;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:#ff5773;
}

.instructor-tabs .tab-btns .tab-btn.active-btn::before,
.instructor-tabs .tab-btns .tab-btn:hover::before{
	opacity:1;
}

.instructor-tabs .tab-btns .tab-btn:hover,
.instructor-tabs .tab-btns .tab-btn.active-btn{
	color:#0f0e2d;
}

.instructor-tabs .tabs-content{
	position:relative;
	overflow:hidden;
	padding:22px 25px;
}

.instructor-tabs .tabs-content .tab{
	position:relative;
	display:none;
}

.instructor-tabs .tabs-content .tab.active-tab{
	display:block;	
}

.instructor-tabs .tabs-content h6{
	position:relative;
	color:#0f0e2d;
	font-size:20px;
	font-weight:500;
	margin-bottom:10px;
}

.instructor-tabs .tabs-content .text{
	position:relative;
}

.instructor-tabs .tabs-content .text p{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:500;
	line-height:2em;
	max-width:900px;
	margin-bottom:15px;
}

.instructor-tabs .tabs-content .text p:last-child{
	margin-bottom:0px;
}

/*** 

====================================================================
	Course Detail Section
====================================================================

***/

.course-detail-section{
	position:relative;
	padding:65px 0px 40px;
}

.course-detail-section .upper-content{
	position:relative;
	padding:15px 15px;
	border-radius:4px;
	background-color:#ffffff;
}

.course-detail-section .upper-content .left-column{
	position:relative;
}

.course-detail-section .upper-content .left-column .inner-column{
	position:relative;
}

/* Review Widget */

.course-detail-section .upper-content .left-column .intro-video{
	position:relative;
	text-align:center;
	width:100%;
	display:block;
	overflow:hidden;
	border-radius:6px;
	padding:75px 0px 75px;
	background-repeat:no-repeat;
	background-size:cover;
}

.course-detail-section .upper-content .left-column .intro-video:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	background-color:rgba(14,123,254,0.50);
}

.course-detail-section .upper-content .left-column .intro-video h4{
	position:relative;
	color:#ffffff;
	font-weight:600;
	font-size:12px;
	line-height:1.3em;
	margin-top:20px;
	text-transform:capitalize;
}

.course-detail-section .upper-content .left-column .intro-video .intro-video-box{
	position: relative;
	width:40px;
	height: 40px;
	z-index:99;
	color: #ff5773;
	font-weight:400;
	font-size:14px;
	text-align: center;
	border-radius:50%;
	padding-left:4px;
	line-height:40px;
	display: inline-block;
	background-color:#ffffff;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	box-shadow:0px 0px 15px rgba(0,0,0,0.10);
}

.course-detail-section .upper-content .left-column .intro-video .ripple,
.course-detail-section .upper-content .left-column .intro-video .ripple:before,
.course-detail-section .upper-content .left-column .intro-video .ripple:after {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    width: 40px;
	margin-left:-1px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
}

.course-detail-section .upper-content .left-column .intro-video .ripple:before {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
}

.course-detail-section .upper-content .left-column .intro-video .ripple:after {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
    position: absolute;
}

.course-detail-section .upper-content .right-column{
	position:relative;
}

.course-detail-section .upper-content .right-column .inner-column{
	position:relative;
}

.course-detail-section .upper-content .right-column h2{
	position:relative;
	color:#0f0e2d;
	font-weight:500;
	line-height:1.2em;
	font-size:24px;
	padding-top:10px;
}

.course-detail-section .upper-content .right-column .sub-text{
	position:relative;
	color:#71718a;
	font-weight:500;
	line-height:1.2em;
	font-size:13px;
	padding-top:10px;
	margin-bottom:15px;
}

.course-detail-section .upper-content .right-column .rating{
	position:relative;
}

.course-detail-section .upper-content .right-column .rating .rate{
	position:relative;
	color:#ffffff;
	font-size:16px;
	font-weight:400;
	padding:4px 22px;
	border-radius:50px;
	display:inline-block;
	background-color:#ff5773;
}

.course-detail-section .upper-content .right-column .rating .rate i{
	margin-left:5px;
}

.course-detail-section .upper-content .right-column .rating .total-rating{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:600;
	margin-left:20px;
}

.course-detail-section .upper-content .right-column .price{
	position:relative;
	color:#0f0e2d;
	font-size:16px;
	font-weight:500;
	margin-top:15px;
}

.course-detail-section .upper-content .right-column .price span{
	position:relative;
	color:#0e7bfe;
	font-weight:700;
}

.course-detail-section .upper-content .right-column .text{
	position:relative;
	color:#71718a;
	font-size:12px;
	line-height:2em;
	font-weight:500;
	max-width:620px;
	margin-top:10px;
	margin-bottom:15px;
}

.course-detail-section .upper-content .right-column .buttons-box{
	position:relative;
}

.course-detail-section .upper-content .right-column .buttons-box .theme-btn{
	position:relative;
	width:100%;
	text-align:center;
	margin-right:20px;
	max-width:170px;
}

.course-detail-section .lower-content{
	position:relative;
	margin-top:1px;
}

/* Course Detail Info Boxed */

.course-detail-info-boxed{
	position:relative;
	padding:15px 70px 15px 25px;
}

.course-detail-info-boxed h6{
	position:relative;
	color:#0f0e2d;
	font-weight:500;
	line-height:1.3em;
	margin-bottom:10px;
}

.course-detail-info-boxed .author{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
	padding-top:5px;
	padding-left:45px;
	display:inline-block;
	margin-top:30px;
}

.course-detail-info-boxed .author .user-image{
	position:absolute;
	left:0px;
	top:0px;
	width:34px;
	height:34px;
	overflow:hidden;
	display:inline-block;
	border-radius:50px;
	border:2px solid #ffffff;
	box-shadow:0px 0px 15px rgba(0,0,0,0.05);
}

.course-detail-info-boxed .follow{
	position:relative;
	margin-left:16px;
	top:1px;
	display:inline-block;
}

.course-detail-info-boxed .follow a{
	position:relative;
	color:#0e7bfe;
	font-size:14px;
	font-weight:600;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.course-detail-info-boxed .follow a:hover{
	color:#ff5773;
}

.course-detail-info-boxed .social-box{
	position:relative;
}

.course-detail-info-boxed .social-box .share{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
	display:block;
	margin-bottom:10px;
}

.course-detail-info-boxed .social-box li{
	position:relative;
	margin:0px 4px;
	display:inline-block;
}

.course-detail-info-boxed .social-box li a{
	position:relative;
	width:30px;
	height:30px;
	color:#3b5998;
	font-size:13px;
	text-align:center;
	line-height:30px;
	border-radius:5px;
	background-color:#eff4ff;
}

.course-detail-info-boxed .social-box li.google a{
	background-color:#ffeded;
	color:#db3236;
}

.course-detail-info-boxed .social-box li.twitter a{
	background-color:#ecfaff;
	color:#00acee;
}

/*Comming Soon*/

.comming-soon{
	position:fixed;
	width:100%;
	height:100%;
	display:block;
	overflow-y:auto;
	background-size:cover;
	background-color:#252841;
}

.comming-soon .content{
	position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: table;
    text-align:center;
    vertical-align: middle;
}

.comming-soon .content .content-inner{
	position: relative;
	padding: 50px 15px;
    display: table-cell;
    vertical-align: middle;
}

.comming-soon .content .content-inner .logo{
	position: relative;
	margin-bottom:40px;
}

.comming-soon .content .content-inner h1{
	position:relative;
	color:#ffffff;
	font-size:54px;
	font-weight:800;
	line-height:1.2em;
	margin-bottom:20px;
	text-transform:capitalize;
}

.comming-soon .content .content-inner .text{
	position:relative;
	color:#ffffff;
	font-size:16px;
	font-weight:500;
	line-height:1.4em;
	margin-bottom:0px;
	text-transform:capitalize;
}

/*** 

====================================================================
	Countdown style
====================================================================

***/

.time-counter{
	position:relative;
}

.time-counter .time-countdown{
	position:relative;
}

.time-counter .time-countdown .counter-column{
	position:relative;
	margin-left:20px;
	width:140px;
	height:140px;
	text-align:left;
	display:inline-block;
	padding:30px 0px 10px;
	color:#ffffff;
	font-size:16px;
	line-height:30px;
	text-align:center;
	text-transform:uppercase;
	font-weight:600;
	margin-bottom:25px;
}

.time-counter .time-countdown .counter-column:before{
	position:absolute;
	content:':';
	right:-25px;
	top:45px;
	font-size:40px;
	font-weight:600;
}

.time-counter .time-countdown .counter-column:last-child:before{
	display:none;
}

.time-counter .time-countdown .counter-column .count{
	position:relative;
	display:block;
	font-size:44px;
	line-height:40px;
	padding:10px 10px 0px;
	color:#ffffff;
	letter-spacing:1px;
	font-weight:500;
	margin-bottom:15px;
}

/* Error Section */

.error-section{
	position:fixed;
	width:100%;
	height:100%;
	display:block;
	overflow-y:auto;
	background-size:cover;
	background-color:#252841;
}

.error-section .content{
	position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: table;
    text-align:center;
    vertical-align: middle;
}

.error-section .content .content-inner{
	position: relative;
	padding: 50px 15px;
    display: table-cell;
    vertical-align: middle;
}

.error-section .content .content-inner .logo{
	position: relative;
	margin-bottom:40px;
}

.error-section .content .content-inner h1{
	position:relative;
	color:#ffffff;
	font-size:74px;
	font-weight:800;
	line-height:1.2em;
	margin-bottom:5px;
	margin-top:-70px;
	text-transform:capitalize;
}

.error-section .content .content-inner .text{
	position:relative;
	color:#ffffff;
	font-size:20px;
	font-weight:500;
	line-height:1.4em;
	margin-bottom:40px;
	text-transform:capitalize;
}

.error-section .content .content-inner .copyright{
	position:relative;
	color:#ffffff;
	font-size:14px;
	font-weight:400;
	line-height:1.4em;
	margin-top:60px;
	text-transform:capitalize;
}

.error-section .content .content-inner .theme-btn{
	padding:7px 75px;
}

/*** 

====================================================================
	Feedback Section
====================================================================

***/

.feedback-section{
	position:relative;
	padding:50px 0px 40px;
}

.feedback-section .inner-container{
	position:relative;
	padding:30px 20px;
	border-radius:4px;
	background-color:#ffffff;
}

.feedback-section .feedback-form{
	
}

/*** 

====================================================================
	Feedback Form
====================================================================

***/

.feedback-form .form-group{
	margin-bottom:20px;
}

.feedback-form .form-group label{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:400;
	display:block;
	font-family: 'Roboto', sans-serif;
}

.feedback-form .form-group .custom-attachment{
	position:absolute;
	left:15px;
	top:30px;
	width:420px;
	height:44px;
	cursor:pointer;
	font-size:14px;
	padding:11px 20px;
	border-radius:50px;
	background-color:#f5fbff;
	color:#71718a !important;
}

.feedback-form .form-group .custom-attachment:before{
	position:absolute;
	content: "\f197";
	right:18px;
	top:9px;
	color:#5ca6fe;
	font-size:26px;
	line-height:1em;
	font-family: "Flaticon";
}

.feedback-form .form-group input[type="text"],
.feedback-form .form-group input[type="password"],
.feedback-form .form-group input[type="tel"],
.feedback-form .form-group input[type="email"],
.feedback-form .form-group select{
	position:relative;
	display:block;
	width:100%;
	line-height:28px;
	padding:10px 30px;
	color:#71718a;
	border:1px solid transparent;
	height:50px;
	font-size:14px;
	border-radius:50px;
	background:#f5fbff;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Roboto', sans-serif;
}

.feedback-form .form-group input[type="text"]:focus,
.feedback-form .form-group input[type="password"]:focus,
.feedback-form .form-group input[type="tel"]:focus,
.feedback-form .form-group input[type="email"]:focus,
.feedback-form .form-group select:focus,
.feedback-form .form-group textarea:focus{
	border-color:#ff5773;
}

.feedback-form .form-group input.attachment-file{
	width:420px;
	height:36px;
	font-size:0px;
	padding:0px;
	border:0px;
	z-index:1;
	opacity:0;
	cursor:pointer;
	padding-left:20px;
}

/* .feedback-form .form-group input.attachment-file:before{
	position:absolute;
	left:15px;
	top:30px;
	content:'';
	width:420px;
	height:36px;
	font-size:0px;
	padding:0px;
	border:0px;
	z-index:10;
	display:inline-block;
	background:url(../images/icons/attachment.png) no-repeat;
} */

.feedback-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:15px 30px 25px;
	color:#71718a;
	border:1px solid transparent;
	height:140px;
	background:#f5fbff;
	resize:none;
	font-size:14px;
	border-radius:15px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Roboto', sans-serif;
}

.feedback-form .theme-btn{
	position:relative;
	margin-top:10px;
	cursor:pointer;
	padding:7px 50px;
}

/*** 

====================================================================
	Help Section
====================================================================

***/

.help-section{
	position:relative;
	padding:45px 0px 30px;
}

.help-section .help-search-box{
	position:relative;
	border-radius:4px;
	text-align:center;
	padding:65px 15px 70px;
	background-color:#252841;
}

.help-section .help-search-box h2{
	position:relative;
	color:#ffffff;
	font-size:24px;
	line-height:1.3em;
	font-weight:700;
	margin-bottom:25px;
	font-family: 'Roboto', sans-serif;
}

.help-section .help-search-box .help-search-form{
	max-width:525px;
	margin:0 auto;
}

/* Subscribe Form */

.help-search-form{
	position:relative;
	width:100%;
	margin-bottom:0px;
}

.help-search-form .form-group{
    position: relative;
    display: block;
    z-index:1;
	margin-bottom: 0px;
}

.help-search-form .form-group input[type="text"],
.help-search-form .form-group input[type="tel"],
.help-search-form .form-group input[type="email"],
.help-search-form .form-group textarea {
    position:relative;
	display:block;
	width:100%;
	height:52px;
	font-size:14px;
	color:#9c9c9c;
	line-height:38px;
	padding:10px 20px 10px 30px;
	font-weight:400;
	border-radius:50px;
	background:#f5fbff;
	transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Roboto', sans-serif;
}

.help-search-form .form-group input[type="email"]:focus{
	border-color:#ffffff;
}

.help-search-form .form-group textarea::-webkit-input-placeholder,
.help-search-form .form-group input::-webkit-input-placeholder{
	color:#9c9c9c;
}

.help-search-form .form-group .theme-btn{
	position: absolute;
	top: 0px;
	right: 0px;
	cursor:pointer;
	padding:11px 50px;
}

.help-search-form .form-group .theme-btn i{
	position:relative;
	font-size:16px;
	margin-right:10px;
}

.help-section .lower-content{
	position:relative;
	margin-top:1px;
}

/*** 

====================================================================
	Help Tabs Style
====================================================================

***/

.help-tabs{
	position:relative;
}

.help-tabs .tab-btns{
	position:relative;
	text-align:center;
	background-color:#ffffff;
}

.help-tabs .tab-btns .tab-btn{
	position:relative;
	display:block;
	font-size:14px;
	background:none;
	color:#71718a;
	font-weight:500;
	line-height:24px;
	cursor:pointer;
	display:inline-block;
	margin-right:15px;
	background-color:#ffffff;
	padding:10px 15px 8px;
	text-transform:capitalize;
	transition:all 500ms ease;
	font-family: 'Roboto', sans-serif;
}

.help-tabs .tab-btns .tab-btn:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:-1px;
	height:1px;
	width:100%;
	opacity:0;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:#ff5773;
}

.help-tabs .tab-btns .tab-btn.active-btn::before,
.help-tabs .tab-btns .tab-btn:hover::before{
	opacity:1;
}

.help-tabs .tab-btns .tab-btn:hover,
.help-tabs .tab-btns .tab-btn.active-btn{
	color:#0f0e2d;
}

.help-tabs .tabs-content{
	position:relative;
	overflow:hidden;
	padding:30px 0px 10px;
}

.help-tabs .tabs-content .tab{
	position:relative;
	display:none;
}

.help-tabs .tabs-content .tab.active-tab{
	display:block;	
}

.help-tabs .tabs-content h3{
	position:relative;
	color:#0f0e2d;
	font-size:24px;
	font-weight:700;
	line-height:1.3em;
	text-align:center;
	margin-bottom:25px;
}

.help-tabs .tabs-content .tab-text{
	position:relative;
	color:#71718a;
	font-size:15px;
	font-weight:500;
	text-align:center;
	top:-15px;
	margin-bottom:15px;
}

/* Featured Block Four */

.feature-block-four{
	position:relative;
	padding-bottom:20px;
}

.feature-block-four .inner-box{
	position:relative;
	border-radius:5px;
	text-align:center;
	padding:20px 15px 35px;
	background-color:#ffffff;
	box-shadow:0px 0px 15px rgba(0,0,0,0.02);
}

.feature-block-four .inner-box .icon-box{
	position:relative;
	width:70px;
	height:70px;
	color:#0f0e2d;
	font-size:34px;
	border-radius:50px;
	line-height:70px;
	text-align:center;
	display:inline-block;
	background-color:#e4f9ff;
}

.feature-block-four.style-two .inner-box .icon-box{
	background-color:#eeefff;
}

.feature-block-four.style-three .inner-box .icon-box{
	background-color:#e7fff4;
}

.feature-block-four.style-four .inner-box .icon-box{
	background-color:#ffedfc;
}

.feature-block-four.style-five .inner-box .icon-box{
	background-color:#f6f5eb;
}

.feature-block-four.style-six .inner-box .icon-box{
	background-color:#fff3f0;
}

.feature-block-four.style-seven .inner-box .icon-box{
	background-color:#e8f6fa;
}

.feature-block-four.style-eight .inner-box .icon-box{
	background-color:#eeefff;
}

.feature-block-four .inner-box h6{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:500;
	line-height:1.3em;
	margin-top:20px;
}

.feature-block-four .inner-box .text{
	position:relative;
	color:#71718a;
	font-size:12px;
	line-height:2em;
	font-weight:500;
	margin-top:10px;
}

/* News Block */

.news-block{
	position:relative;
	margin-bottom:20px;
}

.news-block .inner-box{
	position:relative;
	padding:22px 20px;
	border-radius:5px;
	background-color:#ffffff;
}

.news-block .inner-box .content{
	position:relative;
	padding-left:310px;
}

.news-block .inner-box .content .image{
	position:absolute;
	left:0px;
	top:0px;
	width:280px;
	border-radius:5px;
	overflow:hidden;
}

.news-block .inner-box .content .image img{
	position:relative;
	width:100%;
	display:block;
	transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    -webkit-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
}

.news-block .inner-box .content .image:before{
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 2;
    display: block;
    content: '';
    width: 75%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,87,115,.4) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,87,115,.4) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.news-block .inner-box:hover .image:before{
	-webkit-animation: shine .75s;
    animation: shine .75s;
}

@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}

.news-block .inner-box h6{
	position:relative;
	line-height:1.3em;
	font-weight:500;
	font-size:14px;
}

.news-block .inner-box h6 a{
	position:relative;
	color:#0f0e2d;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.news-block .inner-box h6 a:hover{
	color:#ff5773;
}

.news-block .inner-box .text{
	position:relative;
	color:#71718a;
	font-size:12px;
	line-height:1.8em;
	font-weight:500;
	margin-top:6px;
	margin-bottom:4px;
}

.news-block .inner-box .author{
	position:relative;
	color:#0f0e2d;
	font-size:12px;
	font-weight:600;
}

.news-block .inner-box .news-info{
	position:relative;
	margin-bottom:4px;
}

.news-block .inner-box .news-info li{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:600;
	padding-right:10px;
	margin-right:10px;
	display:inline-block;
}

.news-block .inner-box .news-info li:before{
	position:absolute;
	content:'.';
	right:-5px;
	top:-3px;
	color:#71718a;
	font-size:18px;
	font-weight:800;
}

.news-block .inner-box .news-info li:last-child{
	margin-right:0px;
	padding-right:0px;
}

.news-block .inner-box .news-info li:last-child::before{
	display:none;
}

.news-block .inner-box .reading{
	position:relative;
	color:#ff5773;
	font-size:14px;
	border-bottom:1px solid #ff5773;
	font-family: 'Roboto', sans-serif;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.news-block .inner-box .reading:hover{
	
}

/* News Detail */

.news-detail{
	position:relative;
}

.news-detail .inner-box{
	position:relative;
	padding:25px 22px;
	border-radius:5px;
	background-color:#ffffff;
}

.news-detail .inner-box .image{
	position:relative;
	border-radius:8px;
	overflow:hidden;
	box-shadow:0px 10px 15px rgba(0,0,0,0.07);
}

.news-detail .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.news-detail .inner-box .lower-content{
	position:relative;
	padding:28px 20px 10px;
}

.news-detail .inner-box .lower-content h6{
	position:relative;
	color:#0f0e2d;
	font-size:16px;
	font-weight:500;
	line-height:1.3em;
	margin-bottom:10px;
}

.news-detail .inner-box .lower-content p{
	position:relative;
	color:#71718a;
	font-size:12px;
	line-height:2em;
	font-weight:500;
}

.news-detail .inner-box .lower-content .author{
	position:relative;
	color:#ff5773;
	font-size:12px;
	font-weight:600;
	margin-right:50px;
}

.news-detail .inner-box .lower-content .news-info{
	position:relative;
	margin-bottom:4px;
}

.news-detail .inner-box .lower-content .news-info li{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:600;
	padding-right:10px;
	margin-right:10px;
	display:inline-block;
}

.news-detail .inner-box .lower-content .news-info li:before{
	position:absolute;
	content:'.';
	right:-5px;
	top:-3px;
	color:#71718a;
	font-size:18px;
	font-weight:800;
}

.news-detail .inner-box .lower-content .news-info li:last-child{
	margin-right:0px;
	padding-right:0px;
}

.news-detail .inner-box .lower-content .news-info li:last-child::before{
	display:none;
}

.news-detail .inner-box .lower-content .bold-text{
	position:relative;
	color:#71718a;
	font-size:14px;
	line-height:1.9em;
	font-weight:500;
	padding-top:15px;
	margin-top:18px;
	margin-bottom:18px;
	border-top:1px solid #f5fbff;
}

.news-detail .inner-box .lower-content .bold-text span{
	position:relative;
	color:#0e7bfe;
}

.news-detail .inner-box .lower-content .social-box{
	position:relative;
}

.news-detail .inner-box .lower-content .social-box li{
	position:relative;
	margin-right:8px;
	display:inline-block;
}

.news-detail .inner-box .lower-content .social-box li.share{
	position:relative;
	display:block;
	color:#71718a;
	font-size:14px;
	font-weight:600;
	margin-bottom:10px;
}

.news-detail .inner-box .lower-content .social-box li a{
	position:relative;
	width:30px;
	height:30px;
	color:#3b5998;
	font-size:13px;
	text-align:center;
	line-height:30px;
	border-radius:5px;
	background-color:#eff4ff;
}

.news-detail .inner-box .lower-content .social-box li.google a{
	background-color:#ffeded;
	color:#db3236;
}

.news-detail .inner-box .lower-content .social-box li.twitter a{
	background-color:#ecfaff;
	color:#00acee;
}

/*** 

====================================================================
	Comments Area
====================================================================

 ***/

.sidebar-page-container .comments-area{
	position:relative;
	margin-top:60px;
	margin-bottom:50px;
}

.sidebar-page-container .group-title{
	position:relative;
}

.sidebar-page-container .group-title h6{
	position:relative;
	font-size:18px;
	color:#0f0e2d;
	font-weight:700;
	margin-bottom:25px;
	text-transform:capitalize;
}

.sidebar-page-container .comments-area .comment-box{
	position:relative;
	margin-bottom:30px;
	padding-bottom:25px;
	border-bottom:1px solid #eef0f9;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.sidebar-page-container .comments-area .comment-box:last-child{
	margin-bottom:0px;
}

.sidebar-page-container .comments-area .comment{
	position:relative;
	font-size:14px;
	min-height:115px;
	padding:0px 0px 0px 70px;
}

.sidebar-page-container .comments-area .reply-comment{
	margin-left:90px;
}

.sidebar-page-container .comments-area .comment-box .author-thumb{
	position:absolute;
	left:0px;
	top:0px;
	width:50px;
	border-radius:50%;
	margin-bottom:20px;
	overflow:hidden;
}

.sidebar-page-container .comments-area .comment-box .author-thumb img{
	width:100%;
	display:block;
}

.sidebar-page-container .comments-area .comment-info{
	margin-bottom:8px;
}

.sidebar-page-container .comments-area .comment-box strong{
	font-size:14px;
	font-weight:600;
	color:#0f0e2d;
	display:block;
	line-height:1.3em;
	display:inline-block;
	text-transform:capitalize;
}

.sidebar-page-container .comments-area .comment-box .text{
	color:#71718a;
	font-size:12px;
	line-height:1.9em;
	font-weight:500;
	margin-bottom:10px;
}

.sidebar-page-container .comments-area .comment-info .comment-time{
	position:absolute;
	right:0px;
	top:0px;
	font-size:12px;
	color:#71718a;
	font-weight:600;
	padding-left:20px;
	display:inline-block;
}

.sidebar-page-container .comments-area .comment-info .comment-time:before{
	position:absolute;
	content: "\f133";
	left:0px;
	top:0px;
	color:#0e7bfe;
	font-weight:400;
	font-family: 'FontAwesome';
}

.sidebar-page-container .comments-area .comment-box .theme-btn{
	position:relative;
	font-size:16px;
	color:#00aa15;
	font-weight:600;
	text-transform:capitalize;
}

.sidebar-page-container .comments-area .comment-box .theme-btn:hover{
	color:#3d415b;
}

.sidebar-page-container .comments-area .reply-option{
	position:relative;
}

.sidebar-page-container .comments-area .reply-option li{
	position:relative;
	margin-right:12px;
	display:inline-block;
}

.sidebar-page-container .comments-area .reply-option li a{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-family: 'Roboto', sans-serif;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.sidebar-page-container .comments-area .reply-option li a .icon{
	position:relative;
	margin-right:5px;
}

.sidebar-page-container .comments-area .reply-option li a:hover{
	color:#ff5773;
}

/*** 

====================================================================
	Comment Form
====================================================================

***/

.comment-form .group-title h6{
	position:relative;
}

.comment-form .form-box{
	position:relative;
	padding:25px 22px;
	border-radius:5px;
	background-color:#ffffff;
	box-shadow:0px 10px 20px rgba(0,0,0,0.04);
}

.comment-form .form-group{
	margin-bottom:15px;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select{
	position:relative;
	display:block;
	width:100%;
	line-height:28px;
	padding:10px 25px;
	color:#888888;
	border:1px solid transparent;
	height:46px;
	font-size:14px;
	border-radius:50px;
	background:#f5fbff;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Roboto', sans-serif;
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus{
	border-color:#ff5773;
}

.comment-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:15px 20px 25px;
	color:#888888;
	border:1px solid transparent;
	height:140px;
	background:#f5fbff;
	resize:none;
	border-radius:12px;
	font-size:14px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Roboto', sans-serif;
}

.comment-form .theme-btn{
	position:relative;
	margin-top:10px;
	cursor:pointer;
}

/*** 

====================================================================
	Setting Page Section
====================================================================

***/

.setting-section{
	position:relative;
	padding:50px 0px 50px;
}

/*** 

====================================================================
	Setting Tabs Style
====================================================================

***/

.setting-tabs{
	position:relative;
	max-width:920px;
}

.setting-tabs .tab-btns{
	position:relative;
	padding-left:10px;
	background-color:#ffffff;
}

.setting-tabs .tab-btns .tab-btn{
	position:relative;
	display:block;
	font-size:14px;
	background:none;
	color:#71718a;
	font-weight:500;
	line-height:24px;
	cursor:pointer;
	float:left;
	margin-right:15px;
	background-color:#ffffff;
	padding:10px 15px 8px;
	text-transform:capitalize;
	transition:all 500ms ease;
	font-family: 'Roboto', sans-serif;
}

.setting-tabs .tab-btns .tab-btn:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:-1px;
	height:1px;
	width:100%;
	opacity:0;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:#ff5773;
}

.setting-tabs .tab-btns .tab-btn.active-btn::before,
.setting-tabs .tab-btns .tab-btn:hover::before{
	opacity:1;
}

.setting-tabs .tab-btns .tab-btn:hover,
.setting-tabs .tab-btns .tab-btn.active-btn{
	color:#0f0e2d;
}

.setting-tabs .tabs-content{
	position:relative;
	overflow:hidden;
	padding:22px 25px;
}

.setting-tabs .tabs-content .tab{
	position:relative;
	display:none;
}

.setting-tabs .tabs-content .tab.active-tab{
	display:block;	
}

.setting-tabs .tabs-content h6{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:500;
	margin-bottom:8px;
}

.setting-tabs .tabs-content .text{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:500;
	line-height:2em;
	margin-bottom:15px;
}

.setting-tabs .tabs-content .text p:last-child{
	margin-bottom:0px;
}

.setting-tabs .tabs-content .content{
	position:relative;
	padding:25px 22px 30px;
	border-radius:5px;
	margin-left:-25px;
	margin-right:-25px;
	background-color:#ffffff;
}

/*** 

====================================================================
	Setting Form
====================================================================

***/

.setting-form .form-group{
	margin-bottom:20px;
}

.setting-form .form-group.site-input input{
	padding-left:125px !important;
}

.setting-form .form-group.youtube input{
	padding-left:250px !important;
}

.setting-form .form-group.linkedin input{
	padding-left:250px !important;
}

.setting-form .form-group.twitter input{
	padding-left:240px !important;
}

.setting-form .form-group.facebook input{
	padding-left:260px !important;
}

.setting-form .form-group.youtube .link-name .icon{
	color:#ff0000;
}

.setting-form .form-group.linkedin .link-name .icon{
	color:#0070b2;
}

.setting-form .form-group.twitter .link-name .icon{
	color:#00b8f4;
}

.setting-form .form-group.facebook .link-name .icon{
	color:#305194;
}

.setting-form .form-group .link-name{
	position:absolute;
	left:14px;
	bottom:0px;
	color:#71718a;
	font-size:14px;
	z-index:1;
	height:46px;
	line-height:46px;
	padding-left:20px;
	padding-right:20px;
	background-color:#eef0f9;
	border-radius:50px 0px 0px 50px;
	font-family: 'Roboto', sans-serif;
}

.setting-form .form-group .link-name .icon{
	position:relative;
	top:1px;
	color:#0e7bfe;
	margin-right:5px;
	font-size:16px;
}

.setting-form .form-group label{
	position:relative;
	margin-top:10px;
	color:#71718a;
	font-size:12px;
	font-weight:500;
}

.setting-form .form-group input[type="text"],
.setting-form .form-group input[type="password"],
.setting-form .form-group input[type="tel"],
.setting-form .form-group input[type="email"],
.setting-form .form-group select{
	position:relative;
	display:block;
	width:100%;
	line-height:28px;
	padding:10px 25px;
	color:#888888;
	border:1px solid transparent;
	height:46px;
	font-size:14px;
	border-radius:50px;
	background:#f5fbff;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Roboto', sans-serif;
}

.setting-form .form-group input[type="text"]:focus,
.setting-form .form-group input[type="password"]:focus,
.setting-form .form-group input[type="tel"]:focus,
.setting-form .form-group input[type="email"]:focus,
.setting-form .form-group select:focus,
.setting-form .form-group textarea:focus{
	border-color:#ff5773;
}

.setting-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:15px 20px 25px;
	color:#888888;
	border:1px solid transparent;
	height:140px;
	background:#f5fbff;
	resize:none;
	border-radius:12px;
	font-size:14px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Roboto', sans-serif;
}

.setting-form .theme-btn{
	position:relative;
	margin-top:10px;
	cursor:pointer;
}

.setting-form .form-group button{
	position:relative;
	margin-right:20px;
	max-width:190px;
	width:100%;
}

.setting-form .form-group .btn-style-two{
	background:none;
}

/* Comming Soon */

.signup-section{
	position:relative;
	display:block;
	padding:60px 0px 60px;
	background-size:cover;
	background-color:#f8f9fd;
}

.signup-section .logo{
	position:relative;
	text-align:center;
	margin-bottom:40px;
}

.signup-section .inner-container{
	position:relative;
	max-width:665px;
	padding:35px 35px 50px;
	background-color:#ffffff;
	border-radius:3px;
	margin:0 auto;
	box-shadow:0px 0px 15px rgba(0,0,0,0.04);
}

.signup-section .inner-container h2{
	position:relative;
	font-weight:700;
	color:#0f0e2d;
	font-size:24px;
	line-height:1.3em;
	text-align:center;
}

.signup-section .inner-container .text{
	position:relative;
	color:#71718a;
	font-size:14px;
	line-height:1.8em;
	text-align:center;
	font-weight:500;
	margin-top:10px;
	margin-bottom:30px;
}

.signup-section .copyright{
	position:relative;
	color:#252841;
	font-weight:600;
	text-align:center;
	margin-top:60px;
}

/* Styled Form */

.styled-form{
	position:relative;
}

.styled-form .form-group{
	position:relative;
	margin-bottom:25px;
}

.styled-form .form-group .eye-icon{
	position:absolute;
	right:30px;
	top:45px;
	color:#e1e3ec;
	font-size:18px;
	z-index:1;
	opacity:0.5;
}

.styled-form .form-group label{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:500;
	margin-bottom:10px;
	font-family: 'Roboto', sans-serif;
}

.styled-form .form-group input{
	position: relative;
    display: block;
    width: 100%;
    line-height: 28px;
    padding: 10px 25px;
    color: #888888;
    border: 1px solid transparent;
    height: 46px;
    font-size: 14px;
    border-radius: 50px;
    background: #f5fbff;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    font-family: 'Roboto', sans-serif;
}

.styled-form .form-group textarea:focus,
.styled-form .form-group input:focus{
	border-color:#ff6262;
}

.styled-form .form-group textarea{
	position:relative;
	height:150px;
	width:100%;
	resize:none;
	font-size:14px;
	padding:15px 15px;
	border-radius:20px;
	background: #f5fbff;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	border: 1px solid transparent;
	font-family: 'Roboto', sans-serif;
	border: 1px solid transparent;
}

.styled-form .form-group .users{
	position:relative;
	color:#71718a;
	font-size:14px;
	text-align:center;
	font-weight:500;
}

.styled-form .form-group .users a{
	position:relative;
	color:#ff5773;
	font-weight:600;
	text-decoration:underline;
}

.styled-form .form-group .theme-btn{
	padding:7px 70px;
	cursor:pointer;
}

/* CheckBox */

.styled-form .form-group .check-box{
	position:relative;
	margin-bottom:3px;
	margin-top:7px;
}

.styled-form .form-group .check-box label{
	font-family: 'Roboto Condensed' ;
	color:#2b2b2b;
}

.styled-form .form-group .check-box label{
	position: relative;
	display: block;
	height: 30px;
	width: 100%;
	line-height: 22px;
	padding: 5px 10px 0px;
	padding-left: 25px;
	font-size: 14px;
	font-weight: 400;
	color: #71718a;
	cursor: pointer;
	margin-bottom: 0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: 'Roboto', sans-serif;
}

.styled-form .form-group .check-box input[type="checkbox"]:checked + label{
	border-color: #25a9e0;
}

.styled-form .form-group .form-group .check-box label {
    padding-left: 30px;
    padding-top: 1px;
    cursor: pointer;
}

.styled-form .form-group .check-box label:before{
	position: absolute;
	left: 0;
	top: 7px;
	height:15px;
	width: 15px;
	background: #ffffff;
	content: "";
	border-radius: 3px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	border:1px solid #ff6262;
}

.styled-form .form-group .check-box label:after {
	position: absolute;
	left: 0px;
	top: 0;
	height: 15px;
	line-height: 15px;
	max-width: 0;
	font-size: 14px;
	color: #ffffff;
	font-weight: 800;
	overflow: hidden;
	background: transparent;
	text-align: center;
	font-family: 'FontAwesome';
	-webkit-transition: max-width 500ms ease;
	-moz-transition: max-width 500ms ease;
	-ms-transition: max-width 500ms ease;
	-o-transition: max-width 500ms ease;
	transition: max-width 500ms ease;
}

.styled-form .form-group .check-box input[type="checkbox"] {
	display: none;
}

.styled-form .form-group .check-box input[type="checkbox"]:checked + label:before{
	border: 5px solid #ff6262;
    background: #ffffff;
}

.styled-form .form-group .check-box input[type="checkbox"]:checked + label:after {
	max-width: 20px;
	opacity: 1;	
}

.styled-form .form-group .forgot{
	position:relative;
	color:#71718a;
	font-size:14px;
	margin-top:12px;
	display:block;
	font-family: 'Roboto', sans-serif;
}

.login-section .social-boxed{
	position:relative;
	max-width:315px;
	margin:0 auto;
}

.login-section .social-boxed a{
	position:relative;
	color:#ffffff;
	display:block;
	margin-bottom:15px;
	padding:10px 60px;
	border-radius:50px;
	background-color:#3b5998;
	font-family: 'Roboto', sans-serif;
}

.login-section .social-boxed a.linkedin{
	background-color:#007bb6;
}

.login-section .social-boxed a.twitter{
	background-color:#00aced;
}

.login-section .social-boxed a .icon{
	position:relative;
	margin-right:10px;
}

/*Custom Select*/

.form-group .ui-selectmenu-button.ui-button{
	width:100%;
	font-size:14px;
	font-style:normal;
	height:46px;
	padding:10px 20px 10px 25px;
	line-height:26px;
	color:#71718a;
	border: none;
	border-radius:50px;
	background:#f5fbff;
	border:1px solid transparent;
	font-family: 'Roboto', sans-serif;
}

.ui-selectmenu-menu{
	z-index:9999;
}

.form-group .ui-button .ui-icon{
	background:none;
	position:relative;
	top:0px;
	text-indent:0px;
	color:#333333;	
}

.form-group .ui-button .ui-icon:before{
	font-family: 'FontAwesome';
	content: "\f107";
	position:absolute;
	right:0px;
	top:2px !important;
	top:13px;
	width:10px;
	height:20px;
	display:block;
	color:#999999;
	line-height:20px;
	font-size:16px;
	font-weight:normal;
	text-align:center;
	z-index:5;
	font-weight:800;
}

.ui-widget.ui-widget-content{
	
}

.ui-menu .ui-menu-item{
	font-size:14px;
}

.ui-menu .ui-menu-item:last-child{
	border:none;	
}

.ui-state-active, .ui-widget-content .ui-state-active{
	background:#ff5773;
	border-color:#ff5773;
}

.ui-menu .ui-menu-item-wrapper{
	position:relative;
	display:block;
	padding:8px 20px;
	font-size:14px;
	line-height:24px;
}

.ui-menu-item:hover{
	background-color:#ff5773;
}

/*** 

====================================================================
	Signup Tabs Style
====================================================================

***/

.signup-tabs{
	position:relative;
	max-width:920px;
}

.signup-tabs .tab-btns{
	position:relative;
}

.signup-tabs .tab-btns .tab-btn{
	position:relative;
	display:block;
	font-size:16px;
	background:none;
	color:#0f0e2d;
	font-weight:500;
	line-height:24px;
	cursor:pointer;
	float:left;
	margin-right:1px;
	background-color:#eef0f9;
	padding:15px 87px 14px;
	text-transform:capitalize;
	transition:all 500ms ease;
	font-family: 'Roboto', sans-serif;
}

.signup-tabs .tab-btns .tab-btn:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:-1px;
	height:1px;
	width:100%;
	opacity:0;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:#ff5773;
}

.signup-tabs .tab-btns .tab-btn.active-btn::before,
.signup-tabs .tab-btns .tab-btn:hover::before{
	opacity:1;
}

.signup-tabs .tab-btns .tab-btn:hover,
.signup-tabs .tab-btns .tab-btn.active-btn{
	color:#0f0e2d;
}

.signup-tabs .tabs-content{
	position:relative;
	overflow:hidden;
	padding:22px 0px;
}

.signup-tabs .tabs-content .tab{
	position:relative;
	display:none;
}

.signup-tabs .tabs-content .tab.active-tab{
	display:block;	
}

/* Certificate Box */

.certificate-box{
	position:relative;
	margin-bottom:30px;
}

.certificate-box h3{
	position:relative;
	color:#0f0e2d;
	font-size:24px;
	font-weight:700;
	line-height:1.3em;
	text-align:center;
	margin-top:50px;
}

.certificate-box .sup-text{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:500;
	line-height:1.6em;
	text-align:center;
	margin-top:10px;
}

.certificate-box .box-inner{
	position:relative;
	text-align:center;
	border-radius:4px;
	padding:35px 15px 35px;
	background-color:#ffffff;
	box-shadow:0px 0px 15px rgba(0,0,0,0.05);
}

.certificate-box .box-inner h6{
	position:relative;
	line-height:1.3em;
	color:#0f0e2d;
	font-size:18px;
	font-weight:500;
	margin-bottom:5px;
}

.certificate-box .box-inner .text{
	position:relative;
	color:#71718a;
	font-weight:500;
	margin-top:10px;
	margin-bottom:35px;
}

.certificate-box .box-inner ul{
	position:relative;
	margin-bottom:30px;
}

.certificate-box .box-inner ul li{
	position:relative;
	margin:0px 25px 15px;
	display:inline-block;
}

.certificate-box .box-inner ul li a{
	position:relative;
	color:#ff5773;
	font-size:64px;
	line-height:1em;
}

.certificate-box .box-inner .theme-btn{
	padding:7px 60px;
}

.certificate-widget .form-group{
	margin-bottom:15px;
}

.certificate-widget .form-group .theme-btn{
	background:none;
	padding:7px 50px;
}

/*** 

====================================================================
	Test View Section
====================================================================

***/

.testview-section{
	position:relative;
	padding:65px 0px 85px;
}

.testview-section .auto-container{
	position:relative;
	max-width:840px;
	margin:0 auto;
}

.testview-section .inner-container{
	position:relative;
	padding:30px 30px;
	border-radius:5px;
	background-color:#ffffff;
}

.testview-section .inner-container .upper-box{
	position:relative;
}

.testview-section .inner-container .upper-box .column{
	position:relative;
	margin-bottom:35px;
}

.testview-section .inner-container .upper-box .certificate{
	position:absolute;
	right:0px;
	top:0px;
	color:#ff5773;
	font-size:14px;
	border-bottom:1px solid #ff5773;
	font-family: 'Roboto', sans-serif;
}

.testview-section .inner-container .upper-box .question-box{
	position:relative;
	text-align:center;
	max-width:250px;
	margin:0 auto;
}

.testview-section .inner-container .upper-box .question-box h6{
	position:relative;
	color:#0f0e2d;
	font-size:16px;
	font-weight:500;
	margin-bottom:15px;
}

.testview-section .inner-container .upper-box .question-box .question{
	position:relative;
	width:120px;
	height:120px;
	color:#ff5773;
	font-size:40px;
	margin:0 auto;
	font-weight:700;
	line-height:1.2em;
	border-radius:50px;
	padding:25px 5px;
}

.testview-section .inner-container .upper-box .question-box .question span{
	position:relative;
	color:#71718a;
	font-size:12px;
	display:block;
	font-weight:400;
	line-height:1em;
	margin-top:4px;
	font-family: 'Montserrat', sans-serif;
}

/*** 

====================================================================
	Countdown style
====================================================================

***/

.time-counter-two{
	position:relative;
	width:120px;
	height:120px;
	margin:0 auto;
	padding-top:35px;
	border-radius:50%;
	background-color:#ff5773;
}

.time-counter-two .minutes{
	position:relative;
	color:#ffffff;
}

.time-counter-two .time-countdown-two{
	position:relative;
	display:inline-block;
}

.time-counter-two .time-countdown-two .counter-column{
	position:relative;
	text-align:left;
	display:inline-block;
	color:#ffffff;
	font-size:18px;
	line-height:30px;
	text-align:center;
	text-transform:uppercase;
	font-weight:700;
	margin:0px 5px;
}

.time-counter-two .time-countdown-two .counter-column:before{
	position:absolute;
	content:'.';
	right:-10px;
	top:3px;
	font-size:30px;
	font-weight:600;
}

.time-counter-two .time-countdown-two .counter-column:last-child:before{
	display:none;
}

.time-counter-two .time-countdown-two .counter-column .count{
	position:relative;
	display:block;
	font-size:34px;
	line-height:1em;
	color:#ffffff;
	font-weight:700;
}

.testview-section .inner-container .lower-box{
	position:relative;
	padding:35px 30px;
	border-radius:6px;
	background-color:#eef0f9;
}

.testview-section .inner-container .lower-box h6{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:500;
	line-height:1.8em;
	margin-bottom:15px;
}

.testview-section .inner-container .lower-box .lower-inner-box{
	position:relative;
	max-width:450px;
}

.quiz-form{
	position:relative;
	margin-left:15px;
}

.quiz-form .form-group{
	position:relative;
	margin-left:15px;
	margin-bottom:10px;
}

.quiz-form .check-box{
	position:relative;
	margin-top:0px;
}

.quiz-form .check-box label{
	font-family: 'Roboto Condensed' ;
	color:#2b2b2b;
}

.quiz-form .check-box label{
	position: relative;
	display: block;
	width: 100%;
	line-height: 24px;
	padding: 2px 10px 0px;
	padding-left: 25px;
	font-size: 15px;
	font-weight: 500;
	color: #71718a;
	cursor: pointer;
	margin-bottom: 0;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.quiz-form .check-box input[type="checkbox"]:checked + label{
	border-color: #25a9e0;
}

.quiz-form .form-group .check-box label {
    padding-left: 30px;
    padding-top: 0px;
    cursor: pointer;
}

.quiz-form .check-box label:before{
	position: absolute;
	left: 0;
	top: 7px;
	height:12px;
	width: 12px;
	background: none;
	content: "";
	border-radius: 3px;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
	border:1px solid #c5c5c5;
}

.quiz-form .check-box label:after {
	position: absolute;
	content:'';
	left: 3px;
	top: 10px;
	width:6px;
	height: 6px;
	line-height: 15px;
	font-size: 14px;
	color: #ffffff;
	font-weight: 600;
	overflow: hidden;
	border-radius:50px;
	background: #c5c5c5;
	text-align: center;
	display:inline-block;
	font-family: 'FontAwesome';
	-webkit-transition: max-width 500ms ease;
	-moz-transition: max-width 500ms ease;
	-ms-transition: max-width 500ms ease;
	-o-transition: max-width 500ms ease;
	transition: max-width 500ms ease;
}

.quiz-form .check-box input[type="checkbox"] {
	display: none;
}

.quiz-form .check-box input[type="checkbox"]:checked + label:before{
	border-color:#ff6262;
}

.quiz-form .check-box input[type="checkbox"]:checked + label:after {
	background-color:#ff6262;
}

.quiz-form .form-group textarea{
	position:relative;
	height:90px;
	width:100%;
	resize:none;
	padding:12px 18px;
	border-radius:3px;
	border:1px solid transparent;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Roboto' ;
}

.quiz-form .form-group textarea:focus{
	border-color:#ff5773;
}

.quiz-form .form-group .theme-btn{
	padding:7px 64px;
	margin-top:20px;
	cursor:pointer;
}

/*** 

====================================================================
	Result Section
====================================================================

***/

.result-section{
	position:relative;
	padding:70px 0px 75px;
}

.result-section .inner-container{
	position:relative;
	max-width:840px;
	margin:0 auto;
	text-align:center;
	border-radius:5px;
	padding:40px 15px 40px;
	background-color:#ffffff;
	box-shadow:0px 0px 20px rgba(0,0,0,0.03);
}

.result-section .inner-container .result-options{
	position:relative;
}

.result-section .inner-container .result-options li{
	position:relative;
	color:#0f0e2d;
	font-size:16px;
	font-weight:500;
	margin:0px 18px;
	margin-bottom:30px;
	display:inline-block;
	font-family: 'Roboto', sans-serif;
}

.result-section .inner-container .result-options li .icon{
	position:relative;
	width:80px;
	height:80px;
	color:#ffffff;
	font-size:36px;
	text-align:center;
	line-height:90px;
	margin:0 auto;
	display:block;
	border-radius:50px;
	margin-bottom:20px;
	background-color:#01b200;
	box-shadow:0px 10px 10px rgba(0,0,0,0.07);
}

.result-section .inner-container .result-options li i{
	position:relative;
	font-style:normal;
	color:#71718a;
}

.result-section .inner-container .result-options li:nth-child(2) .icon{
	background-color:#ff5773;
	line-height:80px;
	font-size:30px;
}

.result-section .inner-container .result-options li:nth-child(3) .icon{
	background-color:#0e7bfe;
}

.result-section .inner-container .result-options li .icon.number{
	font-size:26px;
	line-height:80px;
}

.result-section .inner-container h2{
	position:relative;
	color:#0f0e2d;
	font-size:30px;
	font-weight:700;
	line-height:1.3em;
	margin-top:10px;
	margin-bottom:45px;
}

/*** 

====================================================================
	Pricing Section
====================================================================

***/

.pricing-section{
	position:relative;
	padding:70px 0px 80px;
}

.pricing-section .inner-container{
	position:relative;
	padding:40px 40px 20px;
	border-radius:3px;
	background-color:#ffffff;
	box-shadow:0px 5px 20px rgba(0,0,0,0.04);
}

.price-block{
	position:relative;
	margin-bottom:30px;
	-webkit-transition:all 900ms ease;
	-ms-transition:all 900ms ease;
	-o-transition:all 900ms ease;
	-moz-transition:all 900ms ease;
	transition:all 900ms ease;
}

.price-block .inner-box{
	position:relative;
	padding:55px 40px;
	text-align:center;
	border-radius:5px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	box-shadow:0px 5px 15px rgba(0,0,0,0.06);
}

.price-block .inner-box h3{
	position:relative;
	color:#0f0e2d;
	font-weight:700;
	line-height:1.3em;
	margin-top:30px;
	font-size:26px;
	font-family: 'Roboto', sans-serif;
}

.price-block .inner-box .price{
	position:relative;
	color:#ff5773;
	font-weight:700;
	line-height:1.3em;
	margin-top:18px;
	font-size:40px;
	margin-bottom:25px;
}

.price-block .inner-box .price span{
	position:relative;
	display:block;
	color:#71718a;
	font-size:14px;
	font-weight:500;
	margin-top:7px;
	line-height:1.3em;
}

.price-block .inner-box .list{
	position:relative;
	text-align:left;
	margin-bottom:32px;
}

.price-block .inner-box .list li{
	position:relative;
	color:#71718a;
	font-size:14px;
	margin-bottom:8px;
	padding-left:25px;
	font-weight:500;
}

.price-block .inner-box .list li:before{
	position: absolute;
    left: 0px;
	top:0px;
    content: "\f00c";
    color: #0e7bfe;
    font-size: 15px;
    font-weight: 400;
    display: block;
    font-family: 'FontAwesome';
}

.price-block .inner-box .list li.cross:before{
    content: "\f00d";
	color:#ff5773;
}

.price-block .inner-box .list li:last-child{
	margin-bottom:0px;
}

/* Checkout Section */

.checkout-section{
	position:relative;
	padding:25px 30px 10px;
	border-radius:5px;
	background-color:#ffffff;
	box-shadow:0px 0px 15px rgba(0,0,0,0.10);
}

.checkout-section h6{
	position:relative;
	color:#0f0e2d;
	font-size:18px;
	font-weight:500;
	margin-bottom:18px;
}

/* Checked Form */

.checkout-form{
	position:relative;
}

.checkout-form .form-group{
	position:relative;
	margin-bottom:20px;
}

.checkout-form .form-group .icon{
	position:absolute;
	right:40px;
	top:20px;
	color:#ff5773;
	font-size:24px;
	z-index:1;
}

.checkout-form .form-group label{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:500;
	margin-bottom:10px;
	font-family: 'Roboto', sans-serif;
}

.checkout-form .form-group input{
	position: relative;
    display: block;
    width: 100%;
    line-height: 28px;
    padding: 10px 25px;
    color: #888888;
    border: 1px solid transparent;
    height: 46px;
    font-size: 14px;
    border-radius: 50px;
    background: #f5fbff;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    font-family: 'Roboto', sans-serif;
}

.checkout-form .form-group textarea:focus,
.checkout-form .form-group input:focus{
	border-color:#ff6262;
}

.checkout-form .form-group textarea{
	position:relative;
	height:190px;
	width:100%;
	resize:none;
	font-size:14px;
	padding:15px 15px;
	border-radius:5px;
	background: #eef0f9;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	border: 1px solid transparent;
	font-family: 'Roboto', sans-serif;
	border: 1px solid transparent;
}

.checkout-form .form-group h2{
	position:relative;
	color:#0f0e2d;
	font-size:18px;
	font-weight:600;
}

/*** 

====================================================================
	Checkout Tabs Style
====================================================================

***/

.checkout-tabs{
	position:relative;
}

.checkout-tabs .tab-btns{
	position:relative;
}

.checkout-tabs .tab-btns .tab-btn{
	position:relative;
	display:block;
	font-size:16px;
	background:none;
	color:#71718a;
	font-weight:500;
	line-height:24px;
	cursor:pointer;
	width:33%;
	float:left;
	margin-right:1px;
	text-align:center;
	background-color:#eef0f9;
	padding:15px 25px 14px;
	text-transform:capitalize;
	transition:all 500ms ease;
}

.checkout-tabs .tab-btns .tab-btn .icon{
	position:relative;
	margin-right:6px;
}

.checkout-tabs .tab-btns .tab-btn.active-btn .icon,
.checkout-tabs .tab-btns .tab-btn:hover .icon{
	color:#ff5773;
}

.checkout-tabs .tab-btns .tab-btn:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:-1px;
	height:1px;
	width:100%;
	opacity:0;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:#ff5773;
}

.checkout-tabs .tab-btns .tab-btn.active-btn::before,
.checkout-tabs .tab-btns .tab-btn:hover::before{
	opacity:1;
}

.checkout-tabs .tab-btns .tab-btn:hover,
.checkout-tabs .tab-btns .tab-btn.active-btn{
	color:#0f0e2d;
}

.checkout-tabs .tabs-content{
	position:relative;
	overflow:hidden;
	padding:30px 0px 0px;
}

.checkout-tabs .tabs-content .tab{
	position:relative;
	display:none;
}

.checkout-tabs .tabs-content .tab.active-tab{
	display:block;	
}

.checkout-section .order-box{
	position:relative;
}

.checkout-section .order-box li{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	padding-bottom:12px;
	margin-bottom:12px;
	border-bottom:1px solid #e8eff4;
	font-family: 'Roboto', sans-serif;
}

.checkout-section .order-box li span{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:700;
}

/* Order Widget */

.order-widget .order-box{
	position:relative;
	padding-bottom:30px;
}

.order-widget .order-box li{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	padding:12px 20px;
	font-weight:500;
	border-bottom:1px solid #e8eff4;
	font-family: 'Roboto', sans-serif;
}

.order-widget .order-box li span{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:700;
}

.order-widget .order-box li:last-child{
	border-bottom:none;
}

/* Contact Info Section */

.contact-info-section{
	position:relative;
	padding:45px 60px;
	border-radius:5px;
	background-color:#ffffff;
	box-shadow:0px 10px 22px rgba(0,0,0,0.05);
}

.contact-info-section h6{
	position:relative;
	color:#0f0e2d;
	font-size:18px;
	font-weight:600;
	margin-bottom:10px;
}

.contact-info-section .text{
	position:relative;
	color:#71718a;
	font-size:14px;
	line-height:1.8em;
	font-weight:500;
	margin-bottom:20px;
}

.contact-info-section .social-box{
	position:relative;
}

.contact-info-section .social-box li{
	position:relative;
	margin-right:5px;
	display:inline-block;
}

.contact-info-section .social-box li a{
	position:relative;
	width:32px;
	height:32px;
	color:#ffffff;
	font-size:15px;
	line-height:32px;
	border-radius:5px;
	text-align:center;
	display:inline-block;
	background-color:#3b5998;
}

.contact-info-section .social-box li.linkedin a{
	background-color:#007bb6;
}

.contact-info-section .social-box li.twitter a{
	background-color:#00aced;
}

.contact-info-list{
	position:relative;
}

.contact-info-list li{
	position:relative;
	margin-bottom:22px;
	padding-left:40px;
}

.contact-info-list li a{
	position:relative;
	color:#71718a;
	font-size:14px;
	display:block;
	margin-bottom:5px;
	font-weight:500;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.contact-info-list li .icon{
	position:absolute;
	left:0px;
	top:4px;
}

.contact-info-list li a:hover{
	color:#ff5773;
}

/***

==================================================================
	Contact Map Section
==================================================================

***/

.map-boxed iframe{
	position: relative;
	height: 410px;
	width:100%;
}

/* Info Blocks Outer */

.info-blocks-outer{
	position:relative;
}

.info-contact-box{
	position:relative;
	margin-bottom:30px;
}

.info-contact-box .box-inner{
	position:relative;
	text-align:center;
	padding:25px 15px 25px;
	background-color:#ffffff;
	border-radius:5px 5px 0px 0px;
	border-bottom:1px solid transparent;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.info-contact-box .box-inner:hover{
	border-color:#ff5773;
}

.info-contact-box .box-inner .icon-box{
	position:relative;
	width:70px;
	height:70px;
	color:#0f0e2d;
	line-height:70px;
	border-radius:50px;
	overflow:hidden;
	font-size:32px;
	display:inline-block;
	background-color:#e4f9ff;
}

.info-contact-box .box-inner h6{
	position:relative;
	color:#0f0e2d;
	font-weight:500;
	font-size:14px;
	margin-top:10px;
	font-family: 'Roboto', sans-serif;
}

.info-contact-box:nth-child(2) .box-inner .icon-box{
	background-color:#ffedfc;
}

.info-contact-box:nth-child(3) .box-inner .icon-box{
	background-color:#e7fff4;
}

.info-contact-box:nth-child(4) .box-inner .icon-box{
	background-color:#fff3f0;
}

/* Introduction Section */

.introduction-section{
	position:relative;
	margin-bottom:60px;
}

.introduction-section .inner-box{
	position:relative;
}

.introduction-section .inner-box .image{
	position:relative;
	border-radius:4px;
	overflow:hidden;
}

.introduction-section .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.introduction-section .inner-box .image .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	color:#ffffff;
	text-align:center;
	background-color:rgba(0,0,0,0.20)
}

.introduction-section .inner-box .image .overlay-box:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	opacity:0.7;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	background-image: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, #252841 100%);
	background-image: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, #252841 100%);
	background-image: -o-linear-gradient(top, rgba(255,255,255,0) 0%, #252841 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255,255,255,0)), color-stop(100, #252841));
	background-image: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, #252841 100%);
	background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, #252841 100%);
}

.introduction-section .inner-box .image .overlay-inner{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:table;
	vertical-align:middle;
	padding:10px 0px;
}

.introduction-section .inner-box .image .overlay-inner .content{
	position:relative;
	display:table-cell;
	vertical-align:middle;
}

.introduction-section .inner-box .image h2{
	position:relative;
	font-size:35px;
	font-weight:700;
	line-height:1.3em;
}

.introduction-section .inner-box .image h2 a{
	position:relative;
	color:#ffffff;
}

.introduction-section .inner-box .image .post-info{
	position:relative;
	margin-top:12px;
	margin-bottom:25px;
}

.introduction-section .inner-box .image .post-info li{
	position:relative;
	color:#ffffff;
	font-size:14px;
	font-weight:500;
	margin:0px 10px;
	display:inline-block;
}

.introduction-section .inner-box .image .post-info li .icon{
	position:relative;
	margin-right:7px;
}

.introduction-section .inner-box .image .post-info li a{
	position:relative;
	color:#ffffff;
}

.introduction-section .inner-box .image .overlay-box .button-box{
	position:relative;
}

.introduction-section .inner-box .image .overlay-box .button-box .theme-btn{
	margin:0px 10px;
	padding:8px 60px;
}

.introduction-section .inner-box .lower-box{
	position:relative;
	padding:25px 25px;
	border-radius:5px;
	background-color:#ffffff;
}

.introduction-section .inner-box .lower-box .app{
	position:relative;
	padding-left:40px;
	color:#0f0e2d;
	font-size:18px;
	font-weight:700;
	line-height:1.6em;
}

.introduction-section .inner-box .lower-box .app .icon{
	position:absolute;
	left:0px;
	top:2px;
}

.introduction-section .inner-box .lower-box .app i{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-style:normal;
	font-weight:400;
	display:block;
}

.introduction-section .inner-box .lower-box .rating{
	position:relative;
	color:#0f0e2d;
	font-size:15px;
	font-weight:600;
	margin-top:12px;
}

.introduction-section .inner-box .lower-box .rating .rate{
	position: relative;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    padding: 4px 22px;
	margin-left:15px;
    border-radius: 50px;
    display: inline-block;
    background-color: #ff5773;
	box-shadow:0px 10px 20px rgba(0,0,0,0.05);
}

.introduction-section .inner-box .lower-box .rating .rate i{
	margin-left: 5px;
}

.achievement-section{
	position:relative;
	padding-bottom:50px;
}

/* Achieve Block */

.achieve-block{
	position:relative;
	margin-bottom:30px;
}

.achieve-block .inner-box{
	position:relative;
	text-align:center;
	padding:30px 20px 25px;
	background-color:#ffffff;
}

.achieve-block .inner-box .icon{
	position:relative;
	margin-bottom:14px;
}

.achieve-block .inner-box .post-date{
	position:relative;
	color:#0f0e2d;
	font-size:14px;
	font-weight:500;
	font-family: 'Roboto', sans-serif;
}

.achieve-block .inner-box .designation{
	position:relative;
	color:#ff5773;
	font-size:12px;
	font-weight:600;
}

.achieve-block .inner-box .text{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-weight:500;
	line-height:2em;
	margin-top:8px;
}

/***

==================================================================
	Dashboard Section
==================================================================

***/

.dashboard-section{
	position:relative;
	padding:65px 0px 0px;
}

.dashboard-section .owl-dots{
	display:none;
}

.dashboard-section .owl-nav{
	position:absolute;
	right:0px;
	top:-45px;
}

.dashboard-section .owl-nav .owl-prev,
.dashboard-section .owl-nav .owl-next{
	position:relative;
	width:18px;
	height:18px;
	color:#929292;
	font-size:10px;
	line-height:15px;
	text-align:center;
	border-radius:50px;
	margin-left:6px;
	display:inline-block;
	border:1px solid #e8e8e8;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.dashboard-section .owl-nav .owl-prev:hover,
.dashboard-section .owl-nav .owl-next:hover{
	color:#ffffff;
	border-color:#ff5773;
	background-color:#ff5773;
}

/* Dashboard Block */

.dashboard-block{
	position:relative;
	margin-bottom:30px;
}

.dashboard-block .inner-box{
	position:relative;
	padding:25px 25px 32px;
	border-radius:5px;
	background-color:#ffffff;
	box-shadow:0px 5px 20px rgba(0,0,0,0.05);
}

.dashboard-block .inner-box .content{
	position:relative;
	padding-left:75px;
}

.dashboard-block .inner-box .content .icon-box{
	position:absolute;
	left:0px;
	top:5px;
	width:50px;
	height:50px;
	color:#ffffff;
	font-size:30px;
	line-height:50px;
	text-align:center;
	border-radius:4px;
	background-color:#ff5773;
	box-shadow:0px 10px 18px rgba(255,87,115,0.05);
}

.dashboard-block .inner-box .content .courses{
	position:relative;
	color:#71718a;
	font-size:14px;
}

.dashboard-block .inner-box .content .courses span{
	position:relative;
	display:block;
	color:#ff5773;
	font-weight:700;
	display:block;
	font-size:36px;
	margin-top:10px;
}

.dashboard-block:nth-child(2) .inner-box .content .icon-box{
	background-color:#ff9066;
}

.dashboard-block:nth-child(2) .inner-box .content .courses span{
	color:#ff9066;
}

.dashboard-block:nth-child(3) .inner-box .content .icon-box{
	background-color:#f0c142;
}

.dashboard-block:nth-child(3) .inner-box .content .courses span{
	color:#f0c142;
}

.dashboard-block:nth-child(4) .inner-box .content .icon-box{
	background-color:#7033ff;
}

.dashboard-block:nth-child(4) .inner-box .content .courses span{
	color:#7033ff;
}

/* Dashboard Graph Section */

.dashboard-graph-section{
	position:relative;
	margin-top:20px;
	padding-bottom:20px;
}

.dashboard-graph-section .graph-box{
	position:relative;
}

/***

==================================================================
	Earning Section
==================================================================

***/

.earning-section{
	position:relative;
	padding:60px 0px 0px;
}

.earning-graph-box{
	position:relative;
	margin-bottom:60px;
}

.earning-graph-box .inner-graph-box{
	position:relative;
	padding:25px 25px;
	border-radius:7px;
	background-color:#ffffff;
	box-shadow:0px 0px 20px rgba(0,0,0,0.06);
}

.earning-graph-box .inner-graph-box .graph-box{
	position:relative;
}

/* Earning Block */

.earning-block{
	position:relative;
	margin-bottom:30px;
}

.earning-block .inner-box{
	position:relative;
	padding:25px 25px 32px;
	border-radius:5px;
	background-color:#ffffff;
	box-shadow:0px 5px 20px rgba(0,0,0,0.05);
}

.earning-block .inner-box .content{
	position:relative;
	padding-left:85px;
}

.earning-block .inner-box .content .icon-box{
	position:absolute;
	left:0px;
	top:10px;
	width:60px;
	height:60px;
	color:#ffffff;
	font-size:34px;
	line-height:60px;
	text-align:center;
	border-radius:4px;
	background-color:#ff5773;
	box-shadow:0px 10px 18px rgba(255,87,115,0.10);
}

.earning-block .inner-box .content .courses{
	position:relative;
	color:#71718a;
	font-size:12px;
}

.earning-block .inner-box .content .courses span{
	position:relative;
	display:block;
	color:#ff5773;
	font-weight:700;
	display:block;
	font-size:24px;
	line-height:1em;
	margin-top:5px;
}

.earning-block .inner-box .content i{
	position:relative;
	color:#71718a;
	font-size:12px;
	font-style:normal;
	margin-top:5px;
	display:block;
}

.earning-block:nth-child(2) .inner-box .content .icon-box{
	background-color:#ff9066;
}

.earning-block:nth-child(2) .inner-box .content .courses span{
	color:#ff9066;
}

/***

==================================================================
	Statement Section
==================================================================

***/

.statement-section{
	position:relative;
	padding:0px 0px 70px;
}

.statement-section .inner-container{
	position:relative;
	padding:25px 25px;
	border-radius:7px;
	min-width: 800px;
	background-color:#ffffff;
	box-shadow:0px 0px 20px rgba(0,0,0,0.07);
}

.statement-section .inner-container .title-box{
	position:relative;
}

.statement-section .inner-container .title-box .title-column{
	position:relative;
	width:50%;
	float:left;
}

.statement-section .inner-container .title-box .title-column:nth-child(2),
.statement-section .inner-container .title-box .title-column:nth-child(3){
	width:25%;
	float:left;
}

.statement-section .inner-container .title-box h6{
	position:relative;
	color:#0f0e2d;
	font-weight:500;
	font-size:18px;
	margin-bottom:15px;
}

.statement-section .total-revenue{
	position:relative;
	font-weight:700;
	color:#ff5773;
	font-size:18px;
	font-weight:600;
	text-align:right;
	padding:25px 0px 20px;
}

/* Cource Statement Box */

.cource-statement-box{
	position:relative;
	margin-bottom:4px;
}

.cource-statement-box .box-inner{
	position:relative;
	padding:15px 15px;
	border-radius:7px;
	background-color:#ffffff;
	box-shadow:0px 5px 20px rgba(0,0,0,0.05);
}

.cource-statement-box .box-inner .cource-column{
	width:50%;
	float:left;
}

.cource-statement-box .box-inner .fees-column,
.cource-statement-box .box-inner .price-column{
	width:25%;
	float:left;
}

.course-block-two{
	position:relative;
}

.course-block-two .cource-inner-box{
	position:relative;
	padding-left:72px;
	min-height:56px;
	padding-top:4px;
}

.course-block-two .cource-inner-box .cource-image{
	position:absolute;
	left:0px;
	top:0px;
	width:56px;
	height:56px;
	border-radius:5px;
	overflow:hidden;
}

.course-block-two .cource-inner-box h5{
	position:relative;
	line-height:1.3em;
	font-weight:700;
}

.course-block-two .cource-inner-box h5 a{
	position:relative;
	color:#0f0e2d;
	font-size:18px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.course-block-two .cource-inner-box h5 a:hover{
	color:#ff5773;
}

.course-block-two .cource-inner-box .cource-sales{
	position:relative;
	color:#ff5773;
	font-size:14px;
	font-weight:600;
	margin-top:3px;
}

.cource-statement-box .box-inner .cource-fees{
	position:relative;
	line-height:1.3em;
	font-weight:600;
	color:#71718a;
	font-size:14px;
	margin-top:18px;
}

.cource-statement-box .box-inner .cource-price{
	position:relative;
	line-height:1.3em;
	font-weight:600;
	color:#71718a;
	font-size:14px;
	margin-top:18px;
}

.cource-statement-box:nth-child(3) .box-inner .cource-sales,
.cource-statement-box:nth-child(7) .box-inner .cource-sales{
	color:#f6b535;
}

.cource-statement-box:nth-child(4) .box-inner .cource-sales,
.cource-statement-box:nth-child(8) .box-inner .cource-sales{
	color:#7267f3;
}

/***

==================================================================
	Manage Cource Section
==================================================================

***/

.manage-cource-section{
	position:relative;
	padding:60px 0px 80px;
}

.manage-cource-section .inner-container{
	position:relative;
	padding:25px 25px;
	border-radius:7px;
	margin-bottom:40px;
	background-color:#ffffff;
	box-shadow:0px 0px 20px rgba(0,0,0,0.07);
}

.manage-cource-section .inner-container .container-content{
	position:relative;
	min-width: 900px;
}

.manage-cource-section .inner-container .container-content .title-box{
	position:relative;
}

.manage-cource-section .inner-container .container-content .title-box .title-column{
	position:relative;
	width:17%;
	float:left;
	padding:15px 10px;
	text-align:center;
}

.manage-cource-section .inner-container .container-content .title-box .title-column:nth-child(2){
	text-align:right;
}

.manage-cource-section .inner-container .container-content .title-box .title-column:first-child{
	width:30%;
}

.manage-cource-section .inner-container .container-content .title-box .title-column h6{
	position:relative;
	color:#0f0e2d;
	font-size:16px;
	font-weight:500;
}

.manage-cource-section .inner-container table{
    border-spacing: 10px;
    border-collapse: separate;
}

.manage-cource-section .inner-container .table thead tr{
	box-shadow:0px 0px 20px rgba(0,0,0,0.07);
	border-radius:5px;
	margin-bottom:8px;
}

.manage-cource-section .inner-container .table thead tr th{
	padding:20px 30px;
	border:none;
}

.manage-cource-section .inner-container .table thead tr .title{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
}

.manage-cource-section .inner-container .table thead tr .post-date{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
}

.manage-cource-section .inner-container .table thead tr .sales{
	position:relative;
	color:#ff5773;
	font-size:14px;
	font-weight:600;
}

.manage-cource-section .inner-container .table thead tr .category{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
}

.manage-cource-section .inner-container .table thead tr .actions{
	
}

.manage-cource-section .inner-container .table thead tr .actions a{
	position:relative;
	margin:0px 8px;
	color:#71718a;
	font-weight:600;
	font-size:16px;
}

.manage-cource-section .inner-container .table thead tr .actions a:hover{
	color:#ff5773;
}

/* Tbody */

.manage-cource-section .inner-container tbody{
	
}

.manage-cource-section .inner-container tbody tr{
	position:relative;
	box-shadow:0px 0px 16px rgba(0,0,0,0.06);
	border-radius:5px;
	margin-bottom:8px !important;
	
}

.manage-cource-section .inner-container tbody .spacer{
	position:relative;
	height:8px;
	box-shadow:inherit;
}

.manage-cource-section .inner-container tbody tr th{
	padding:20px 30px;
	border:none;
}

.manage-cource-section .inner-container tbody .title{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
}

.manage-cource-section .inner-container tbody tr .post-date{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
}

.manage-cource-section .inner-container tbody tr .sales{
	position:relative;
	color:#ff5773;
	font-size:14px;
	font-weight:600;
}

.manage-cource-section .inner-container tbody tr:nth-child(2n + 0) .sales{
	color:#f6b535;
}

.manage-cource-section .inner-container tbody tr:nth-child(2n + 0) .sales{
	color:#f6b535;
}

.manage-cource-section .inner-container tbody tr:nth-child(3n + 0) .sales{
	color:#7267f3;
}

.manage-cource-section .inner-container tbody tr .category{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:600;
}

.manage-cource-section .inner-container tbody tr .actions{
	
}

.manage-cource-section .inner-container tbody tr .actions a{
	position:relative;
	margin:0px 8px;
	color:#71718a;
	font-weight:600;
	font-size:16px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.manage-cource-section .inner-container tbody tr .actions a:hover{
	color:#ff5773;
}

/***

==================================================================
	Edit Cource Section
==================================================================

***/

.edit-cource-section{
	position:relative;
	padding:60px 0px 80px;
}

.edit-cource-section .inner-container{
	position:relative;
	padding:25px 35px 60px;
	border-radius:7px;
	margin-bottom:40px;
	background-color:#ffffff;
	box-shadow:0px 0px 20px rgba(0,0,0,0.07);
}

.edit-cource-section .left-column{
	position:relative;
}

.edit-cource-section .left-column .inner-column{
	position:relative;
}

.edit-cource-section .left-column h6{
	position:relative;
	color:#0f0e2d;
	font-size:18px;
	font-weight:500;
	line-height:1.3em;
	margin-bottom:15px;
}

/* Edit Course Form */

.edit-course-form{
	position:relative;
}

.edit-course-form .form-group{
	position:relative;
	margin-bottom:20px;
}

.edit-course-form .form-group .support{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:400;
	display:block;
	padding:12px 20px;
	background: #f5fbff;
	border-bottom:1px solid #dfe1ef;
}

.edit-course-form .form-group .support strong{
	position:relative;
	font-weight:600;
}

.edit-course-form .form-group label{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:500;
	margin-bottom:10px;
	font-family: 'Roboto', sans-serif;
}

.edit-course-form .form-group input{
	position: relative;
    display: block;
    width: 100%;
    line-height: 28px;
    padding: 10px 25px;
    color: #888888;
    border: 1px solid transparent;
    height: 46px;
    font-size: 14px;
    border-radius: 5px;
    background: #f5fbff;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    font-family: 'Roboto', sans-serif;
}

.edit-course-form .form-group textarea:focus,
.edit-course-form .form-group input:focus{
	border-color:#ff6262;
}

.edit-course-form .form-group textarea{
	position:relative;
	height:200px;
	width:100%;
	resize:none;
	font-size:14px;
	padding:15px 15px;
	border-radius:5px;
	background: #f5fbff;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	border: 1px solid transparent;
	font-family: 'Roboto', sans-serif;
	border: 1px solid transparent;
}

.edit-course-form .form-group .btn-style-two{
	background-color:#ffffff;
	padding:5px 48px;
	cursor:pointer;
	margin-top:20px;
}

/* Right Column */

.edit-cource-section .right-column{
	position:relative;
}

.edit-cource-section .right-column .inner-column{
	position:relative;
}

.edit-cource-section .right-column h6{
	position:relative;
	color:#0f0e2d;
	font-size:18px;
	font-weight:500;
	line-height:1.3em;
	margin-bottom:22px;
}

.edit-cource-section .right-column .video-boxed{
	position:relative;
	text-align:center;
	width:100%;
	display:block;
	overflow:hidden;
	border-radius:5px 5px 0px 0px;
	padding:88px 0px;
	background-repeat:no-repeat;
	background-size:cover;
}

.edit-cource-section .right-column .video-boxed:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	right:0px;
	bottom:0px;
	background-color:rgba(255,87,115,0.50);
}

.edit-cource-section .right-column .video-boxed .intro-video-box{
	position: relative;
	width:40px;
	height: 40px;
	z-index:99;
	color: #ff5773;
	font-weight:400;
	font-size:14px;
	text-align: center;
	border-radius:50%;
	padding-left:4px;
	line-height:40px;
	display: inline-block;
	background-color:#ffffff;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	box-shadow:0px 0px 15px rgba(0,0,0,0.10);
}

.edit-cource-section .right-column .video-boxed .ripple,
.edit-cource-section .right-column .video-boxed .ripple:before,
.edit-cource-section .right-column .video-boxed .ripple:after {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    width: 40px;
	margin-left:-1px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
}

.edit-cource-section .right-column .video-boxed .ripple:before {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
}

.edit-cource-section .right-column .video-boxed .ripple:after {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
    position: absolute;
}

/* Url Boxed */

.url-boxed{
	position:relative;
	padding:25px 25px;
	margin-bottom:30px;
	background-color:#f5fbff;
}

.url-boxed label{
	position:relative;
    color: #71718a;
	display:block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
}

.url-boxed input{
	position: relative;
    display: block;
    width: 100%;
    line-height: 28px;
    padding: 10px 25px;
    color: #888888;
    border: 1px solid #dfe1ef;
    height: 46px;
    font-size: 14px;
    border-radius: 50px;
    background: #f5fbff;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    font-family: 'Roboto', sans-serif;
}

.url-boxed .valid{
	position:relative;
    color: #71718a;
	display:block;
    font-size: 12px;
    font-weight: 400;
    margin-top: 15px;
}

/* Option Cource Box */

.option-cource-box{
	position:relative;
	padding:25px 25px;
	background-color:#f5fbff;
}

.option-cource-box .form-group{
	position:relative;
}

.option-cource-box .form-group .select-category{
	position:relative;
	color:#71718a;
	font-size:14px;
	font-weight:500;
	margin-top:12px;
	display:block;
}

.option-cource-box .form-group label{
	position: relative;
    color: #71718a;
	display:block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
}

.option-cource-box .form-group .ui-selectmenu-button.ui-button{
	border:1px solid #dfe1ef;
}

.option-cource-box .form-group .price{
	position: relative;
    color: #71718a;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
	display:block;
	text-transform:capitalize;
    font-family: 'Roboto', sans-serif;
}

.option-cource-box .form-group .item-quantity{
	position:relative;
	width:120px;
}

.option-cource-box .form-group .total-price{
	position:relative;
	color: #71718a;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
	display:block;
	margin-top:15px;
	text-transform:capitalize;
    font-family: 'Roboto', sans-serif;
}

.option-cource-box .form-group .item-quantity .quantity-spinner{
	background-color:#f5fbff;
	line-height: 24px;
    padding: 10px 25px !important;
    height: 54px !important;
    box-shadow: none !important;
    border-radius: 50px 0px 0px 50px;
    border: 0px;
	font-size:14px;
	border:1px solid #dfe1ef;
}

.bootstrap-touchspin .input-group-btn-vertical{
	right: 1px;
	position: absolute;
}

.bootstrap-touchspin .glyphicon-chevron-up:before {
    content: "\f106";
	font-size: 18px;
	font-style:normal;
	font-family: 'FontAwesome';
}

.bootstrap-touchspin .glyphicon-chevron-down:before {
    content: "\f107";
	font-size: 18px;
	font-style:normal;
	font-family: 'FontAwesome';
}

.bootstrap-touchspin .input-group-btn-vertical{
	width:30px;
	display: block !important;
    z-index: 10;
	border-left:1px solid #dddddd;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn,
.bootstrap-touchspin .input-group-btn-vertical > .btn{
	padding:13px 10px;
	background:none;
	left:4px;
}

.bootstrap-touchspin .input-group-btn-vertical i,
.bootstrap-touchspin .input-group-btn-vertical i{
	top:1px;	
}

.option-cource-box .form-group.tags a{
	position:relative;
	padding:5px 10px;
	color:#71718a;
	font-size:12px;
	margin-right:5px;
	font-weight:400;
	margin-bottom:10px;
	display:inline-block;
	background-color:#ffffff;
	-webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
	box-shadow:0px 5px 15px rgba(0,0,0,0.07);
}

.option-cource-box .form-group.tags a:nth-child(3n + 0){
	
}

.option-cource-box .form-group.tags a:hover{
	background-color:#ff5773;
	color:#ffffff;
}

.edit-cource-section .right-column .button-box{
	position:relative;
	margin-top:40px;
	text-align:center;
}

.edit-cource-section .right-column .button-box .theme-btn{
	padding:7px 30px;
	margin:0px 5px 10px;
	cursor:pointer;
}

.edit-cource-section .right-column .button-box .btn-style-two{
	background:none;
}

.sidebar-widget.certificate-widget .widget-content .content{
	padding:15px 20px 5px;
}

/* Dark Layout Code */

.dark-body .page-wrapper{
	background-color:#2d3049;
}

.dark-body .main-header{
	background-color:#252841;
}

.dark-body .main-header .header-left .form-group input[type="text"],
.dark-body .main-header .header-left .form-group input[type="search"]{
	background-color:#2d3049;
	color:#ffffff;
}

.dark-body .sec-title h4{
	color:#f5f5f5;
}

.dark-body .sidebar-widget .widget-content{
	background-color:#252841;
}

.dark-body .course-tabs .tab-btns,
.dark-body .review-comments-box h6,
.dark-body .checkout-section .order-box li,
.dark-body .order-widget .order-box li,
.dark-body .edit-course-form .form-group .support,
.dark-body .news-detail .inner-box .lower-content .bold-text,
.dark-body .sidebar-page-container .comments-area .comment-box,
.dark-body .recent-posts .post,
.dark-body .url-boxed input,
.dark-body .sidebar-title{
	border-color:rgba(255,255,255,0.08);
}

.dark-body .option-cource-box .form-group .ui-selectmenu-button.ui-button{
	border-color:rgba(255,255,255,0.08);
	color:#f5f5f5;
}

.dark-body .main-header .header-right .options-box .notification-block,
.dark-body .main-header .header-right .options-box .profile-box .profile-content h6,
.dark-body .main-header .header-right .options-box .profile-box .profile-content .dark-mode,
.dark-body .bootstrap-touchspin .input-group-btn-vertical,
.dark-body .option-cource-box .form-group .item-quantity .quantity-spinner,
.dark-body .main-header .header-right .options-box .profile-box .profile-content .setting{
	border-color:rgba(255,255,255,0.20);
}

.dark-body .feedback-form .form-group label,
.dark-body .url-boxed .valid,
.dark-body .url-boxed label,
.dark-body .setting-tabs .tabs-content h6,
.dark-body .class-detail-content h4,
.dark-body .course-tabs .tab-btns .tab-btn:hover,
.dark-body .course-tabs .tab-btns .tab-btn.active-btn,
.dark-body .reviewer-comment-box h4,
.dark-body .student-review-box .stars-column .rating i,
.dark-body .student-review-box .rating-column .inner-column .title,
.dark-body .instructor-tabs .tabs-content h6,
.dark-body .accordion-box .block .acc-btn,
.dark-body .edit-course-form .form-group label,
.dark-body .option-cource-box .form-group label,
.dark-body .accordion-box.style-two .block .content .text-link,
.dark-body .accordion-box.style-two .block .content .minutes,
.dark-body .instructor-tabs .tab-btns .tab-btn:hover,
.dark-body .instructor-tabs .tab-btns .tab-btn.active-btn,
.dark-body .instructor-page-section .upper-content .content h4,
.dark-body .fact-counter .counter-column .counter-title,
.dark-body .fact-counter .counter-column .count-box,
.dark-body .setting-tabs .tab-btns .tab-btn:hover,
.dark-body .setting-tabs .tab-btns .tab-btn.active-btn,
.dark-body .testview-section .inner-container .upper-box .question-box h6,
.dark-body .main-header .header-right .options-box .profile-box .profile-content .dark-buttons li,
.dark-body .checkout-section .order-box li span,
.dark-body .support-widget .widget-content h5,
.dark-body .sidebar-title h6,
.dark-body .main-header .header-right .options-box .profile-box .profile-content .setting a{
	color:#f5f5f5;
}

.dark-body .tags-widget ul li a{
	color:#999ebe;
	background-color:rgba(255,255,255,0.03);
}

.dark-body .categories-widget ul li a{
	color:#999ebe;
}

.dark-body .categories-widget ul li a .icon{
	color:#999ebe;
	background-color:rgba(255,255,255,0.03);
}

.dark-body .support-widget .widget-content .text{
	color:#999ebe;
}

.dark-body .accordion-box .block,
.dark-body .course-tabs,
.dark-body .news-detail .inner-box,
.dark-body .featured-section-two,
.dark-body .sidenav-list,
.dark-body .hidden-bar,
.dark-body .dashboard-block .inner-box,
.dark-body .contact-info-section,
.dark-body .checkout-section,
.dark-body .help-tabs .tab-btns .tab-btn,
.dark-body .help-tabs .tab-btns,
.dark-body .comment-form .form-box,
.dark-body .testimonial-block .inner-box,
.dark-body .team-block .inner-box,
.dark-body .course-block .inner-box,
.dark-body .news-block .inner-box,
.dark-body .course-tabs .tabs-content,
.dark-body .certificate-box .box-inner,
.dark-body .feature-block-four .inner-box,
.dark-body .info-contact-box .box-inner,
.dark-body .instructor-tabs .tab-btns,
.dark-body .achieve-block .inner-box,
.dark-body .earning-graph-box .inner-graph-box,
.dark-body .course-tabs .tab-btns .tab-btn,
.dark-body .instructor-tabs .tab-btns .tab-btn,
.dark-body .course-detail-section .upper-content,
.dark-body .main-header.fixed-header .header-upper,
.dark-body .feature-block-two .inner-box,
.dark-body .feedback-section .inner-container,
.dark-body .signup-section .inner-container,
.dark-body .instructor-page-section .upper-content,
.dark-body .manage-cource-section .inner-container,
.dark-body .pricing-section .inner-container,
.dark-body .setting-tabs .tab-btns,
.dark-body .setting-tabs .tab-btns .tab-btn,
.dark-body .setting-tabs .tabs-content .content,
.dark-body .testview-section .inner-container,
.dark-body .main-header .header-right .options-box .notification-block,
.dark-body .main-header .header-right .options-box .profile-box .profile-content,
.dark-body .introduction-section .inner-box .lower-box,
.dark-body .statement-section .inner-container{
	background-color:#252841;
}

.dark-body .course-block .inner-box .image .time{
	color:#ffffff;
	background-color:#252841;
}

.dark-body .class-detail-content h6,
.dark-body .review-widget .price,
.dark-body .checkout-section h6,
.dark-body .certificate-box h3,
.dark-body .news-block .inner-box .author,
.dark-body .news-block .inner-box h6 a,
.dark-body .recent-posts .post .text a,
.dark-body .team-block .inner-box .name,
.dark-body .team-block .inner-box .courses,
.dark-body .testimonial-block .inner-box .name,
.dark-body .feature-block-three .inner-box h5,
.dark-body .certificate-box .box-inner h6,
.dark-body .order-widget .order-box li,
.dark-body .checkout-tabs .tab-btns .tab-btn,
.dark-body .order-widget .order-box li span,
.dark-body .feature-block-four .inner-box h6,
.dark-body .course-block .inner-box .lower-content .price,
.dark-body .course-block .inner-box .lower-content h6 a,
.dark-body .feature-block-two .inner-box .price,
.dark-body .sidebar-page-container .group-title h6,
.dark-body .sidebar-page-container .comments-area .comment-box strong,
.dark-body .news-detail .inner-box .lower-content h6,
.dark-body .feature-block-two .inner-box h6 a,
.dark-body .help-tabs .tab-btns .tab-btn:hover,
.dark-body .checkout-form .form-group h2,
.dark-body .checkout-section .order-box li,
.dark-body .contact-info-section h6,
.dark-body .video-info-boxed h6,
.dark-body .signup-section .copyright,
.dark-body .help-tabs .tabs-content h3,
.dark-body .student-review-box h6,
.dark-body .popular-posts .post .text a,
.dark-body .review-comments-box h6,
.dark-body .info-contact-box .box-inner h6,
.dark-body .reviewer-comment-box .helpful,
.dark-body .edit-cource-section .right-column h6,
.dark-body .edit-cource-section .left-column h6,
.dark-body .option-cource-box .form-group .price,
.dark-body .option-cource-box .form-group .total-price,
.dark-body .achieve-block .inner-box .post-date,
.dark-body .course-block-two .cource-inner-box h5 a,
.dark-body .fact-counter .counter-column .count-text,
.dark-body .signup-section .inner-container h2,
.dark-body .price-block .inner-box h3,
.dark-body .result-section .inner-container h2,
.dark-body .result-section .inner-container .result-options li,
.dark-body .introduction-section .inner-box .lower-box .rating,
.dark-body .introduction-section .inner-box .lower-box .app,
.dark-body .statement-section .inner-container .title-box h6,
.dark-body .manage-cource-section .inner-container tbody .title,
.dark-body .signup-tabs .tab-btns .tab-btn:hover,
.dark-body .signup-tabs .tab-btns .tab-btn.active-btn,
.dark-body .testview-section .inner-container .lower-box h6,
.dark-body .manage-cource-section .inner-container .container-content .title-box .title-column h6,
.dark-body .main-header .header-right .options-box .notification-block .inner .post-time,
.dark-body .main-header .header-right .options-box .notification-block .inner h3 a,
.dark-body .main-header .header-right .options-box .profile-box .profile-content .dark-mode .mode,
.dark-body .main-header .header-right .options-box .profile-box .profile-content h6,
.dark-body .course-detail-section .upper-content .right-column .price,
.dark-body .course-detail-section .upper-content .right-column h2,
.dark-body .help-tabs .tab-btns .tab-btn.active-btn{
	color:#f5f5f5;
}

.dark-body .signup-section .inner-container .text,
.dark-body .class-detail-content .text,
.dark-body .review-widget .time-left,
.dark-body .reviewer-comment-box .text,
.dark-body .list-style-one li,
.dark-body .contact-info-list li a,
.dark-body .certificate-box .sup-text,
.dark-body .recent-posts .author,
.dark-body .contact-info-section .text,
.dark-body .news-block .inner-box .news-info li,
.dark-body .news-block .inner-box .text,
.dark-body .option-cource-box .form-group .select-category,
.dark-body .feature-block-three .inner-box .text,
.dark-body .testimonial-block .inner-box .text,
.dark-body .team-block .inner-box .designation,
.dark-body .news-detail .inner-box .lower-content p,
.dark-body .course-block .inner-box .lower-content .author,
.dark-body .course-block .inner-box .lower-content .post-meta li,
.dark-body .feature-block-two .inner-box .author,
.dark-body .feature-block-two .inner-box .text,
.dark-body .certificate-box .box-inner .text,
.dark-body .feature-block-four .inner-box .text,
.dark-body .instructor-tabs .tabs-content .text p,
.dark-body .reviewer-comment-box .rating,
.dark-body .popular-posts .timing,
.dark-body .setting-form .form-group label,
.dark-body .achieve-block .inner-box .text,
.dark-body .introduction-section .inner-box .lower-box .app i,
.dark-body .cource-statement-box .box-inner .cource-fees,
.dark-body .cource-statement-box .box-inner .cource-price,
.dark-body .earning-block .inner-box .content .courses,
.dark-body .dashboard-block .inner-box .content .courses,
.dark-body .price-block .inner-box .price span,
.dark-body .price-block .inner-box .list li,
.dark-body .setting-tabs .tabs-content .text,
.dark-body .quiz-form .check-box label,
.dark-body .result-section .inner-container .result-options li i,
.dark-body .manage-cource-section .inner-container tbody tr .actions a,
.dark-body .manage-cource-section .inner-container tbody tr .category,
.dark-body .manage-cource-section .inner-container tbody tr .post-date,
.dark-body .testview-section .inner-container .upper-box .question-box .question span,
.dark-body .main-header .header-right .options-box .notification-block .inner .notification-text,
.dark-body .main-header .header-right .options-box .profile-box .pages li a,
.dark-body .course-detail-section .upper-content .right-column .text,
.dark-body .course-detail-section .upper-content .right-column .rating .total-rating,
.dark-body .course-detail-section .upper-content .right-column .sub-text,
.dark-body .sidebar-page-container .comments-area .reply-option li a,
.dark-body .sidebar-page-container .comments-area .comment-info .comment-time,
.dark-body .sidebar-page-container .comments-area .comment-box .text{
	color:#999ebe;
}

.dark-body .signup-section,
.dark-body .price-block .inner-box,
.dark-body .checkout-tabs .tab-btns .tab-btn,
.dark-body .hidden-bar .pages-list li.active a,
.dark-body .hidden-bar .pages-list li a:hover{
	background-color:#2d3049;
}

.dark-body .sidenav-list ul li a .icon{
	background-color:rgba(255,255,255,0.04);
}

.dark-body .earning-block .inner-box,
.dark-body .edit-course-form .form-group .support,
.dark-body .cource-statement-box .box-inner,
.dark-body .edit-cource-section .inner-container,
.dark-body .team-block .inner-box .social-box li a{
	background-color:rgba(255,255,255,0.04);
}

.dark-body .testimonial-block .inner-box .quote{
	color:rgba(255,255,255,0.06);
}

.dark-body .form-group .ui-selectmenu-button.ui-button{
	background-color:rgba(255,255,255,0.07);
}

.dark-body .feedback-form .form-group input[type="text"],
.dark-body .feedback-form .form-group input[type="password"],
.dark-body .feedback-form .form-group input[type="tel"],
.dark-body .feedback-form .form-group input[type="email"],
.dark-body .feedback-form .form-group select,
.dark-body .feedback-form .form-group textarea,
.dark-body .edit-course-form .form-group input,
.dark-body .edit-course-form .form-group textarea,
.dark-body .checkout-form .form-group input,
.dark-body .styled-form .form-group input,
.dark-body .setting-form .form-group textarea,
.dark-body .checkout-form .form-group textarea,
.dark-body .comment-form .form-group input[type="text"],
.dark-body .comment-form .form-group input[type="password"],
.dark-body .comment-form .form-group input[type="tel"],
.dark-body .comment-form .form-group textarea,
.dark-body .comment-form .form-group input[type="email"],
.dark-body .comment-form .form-group select{
	background-color:rgba(255,255,255,0.07);
	color:#ffffff;
}

.dark-body .url-boxed input,
.dark-body .edit-course-form .form-group .btn-style-two{
	background:none;
}


.dark-body .fact-counter .counter-column .inner,
.dark-body .option-cource-box,
.dark-body .url-boxed,
.dark-body .testview-section .inner-container .lower-box,
.dark-body .result-section .inner-container,
.dark-body .styled-form .form-group .check-box label:before,
.dark-body .accordion-box .block .play-icon .fa,
.dark-body .skills .skill-item .skill-bar .bar-inner,
.dark-body .feature-block-three .inner-box .icon,
.dark-body .styled-pagination li a{
	background-color:rgba(255,255,255,0.07);
}

.dark-body .help-search-form .form-group input[type="text"],
.dark-body .help-search-form .form-group input[type="tel"],
.dark-body .help-search-form .form-group input[type="email"],
.dark-body .help-search-form .form-group textarea,
.dark-body .feedback-form .form-group .custom-attachment,
.dark-body .main-header .header-right .options-box .profile-box .profile-content .dark-mode .icon,
.dark-body .option-cource-box .form-group .item-quantity .quantity-spinner,
.dark-body .option-cource-box .form-group.tags a,
.dark-body .styled-form .form-group textarea,
.dark-body .setting-form .form-group input[type="text"],
.dark-body .setting-form .form-group input[type="password"],
.dark-body .setting-form .form-group input[type="tel"],
.dark-body .setting-form .form-group input[type="email"],
.dark-body .setting-form .form-group select{
	background-color:rgba(255,255,255,0.07);
	color:#f5f5f5;
}

.dark-body .main-header .header-right .options-box .notification-box .view{
	background-color:#2d3049;
	color:#f5f5f5;
}

.dark-body .setting-form .form-group .link-name{
	color:#f5f5f5;
	background-color:rgba(255,255,255,0.10);
}

.dark-body .quiz-form .form-group textarea,
.dark-body .signup-tabs .tab-btns .tab-btn{
	background-color:rgba(255,255,255,0.07);
	color:#f5f5f5;
}

.dark-body .dark-layout-logo{
	opacity:1 !important;
	visibility:visible !important;
}

.dark-body .logo{
	opacity:0;
	visibility:hidden;
}