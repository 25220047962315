.apps_div {
    margin-top: 30px;
}
.apps_div > ul{
    display: flex;
    flex-wrap: wrap;
    li{
        width: 25%;
        // height: 120px;
        padding: 15px;
        margin: auto;
        a{
            display: block;
            height: 100%;
            background: #ff5773;
            color: #fff;
            // line-height: 90px;
            text-align: center;
            font-size: 18px;
            padding: 15px;
            border-radius: 10px;
            transition: all ease-in-out .3s;
            &:hover{
                transform: scale(1.05, 1.05);
                box-shadow: 5px 5px 12px #999;
                //font-size: 28px;
            }
            img{
                width: 75px;
            }
        }
    }
    @media only screen and (max-width: 992px){
        li{
            width: 50%;
        }
    }
    @media only screen and (max-width: 479px){
        li{
            width: 100%;
        }
    }
}

.app_version{
    position: fixed;
    top: 10px;
    left: 15px;
}