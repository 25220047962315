
*{
    font-family: 'Cairo', sans-serif;
}
p, div, h1, h2, h3, h4, h5, h6, li, input, button, span.user_name_span, label{
    font-family: 'Cairo', sans-serif !important;
}
body{
    font-size: 14px !important;
    line-height: 1.7em !important;
}
h4 {
    font-size: 22px !important;
}
strong{
    color: #5c6873 !important;
    font-weight: bolder !important;
}
.list-group-item{
    //padding: 0.75rem 1.25rem !important;
}
.feature-block-two a{
    color: #ff5773 !important;
}
a:hover{
    color: #ff5773;
}
.form-control:focus{
    outline: none !important;
    box-shadow: none;
}
.btn-style-one:before{
    border-radius: 50px;
}
.btn-style-one:disabled{
    cursor: not-allowed !important;
}
.btn-style-one:hover{
    color: #fff;
    border-color: #23282c;
}
.mainDiv{
    margin-top: 20px;
}
.row{
    width: 100%;
    margin: 0px;
}
.alert-danger{
    border-radius: 35px;
}
.styled-form .form-group select{
    position: relative;
    display: block;
    width: 100%;
    line-height: 28px;
    padding: 10px 25px;
    color: #888888;
    border: 1px solid transparent;
    height: 46px;
    font-size: 14px;
    border-radius: 50px;
    background: #f5fbff;
    transition: all 300ms ease;
    font-family: 'Roboto', sans-serif;
}
.styled-form .form-group select:focus{
    border-color: #ff6262;
}
.app-header{
    height: 75px;
    min-height: auto !important;
    position: fixed !important;
    z-index: 1020 !important;
}
.app-header .navbar-brand{
    height: 75px;
}
.header-fixed .app-body{
    margin-top: 75px;
}
@media (min-width: 992px){
    .sidebar-fixed .app-header + .app-body .sidebar {
        height: calc(100vh - 75px);
    }
}

.ml-auto.navbar-nav{
    margin-left: 10px !important;
}
.ml-auto.navbar-nav > li{
    padding-right: 15px;
}
@media (max-width: 991.98px){
    .app-header .navbar-brand {
        right: 50px;
        height: 75px;
        width: 90px;
    }
    .main .container-fluid{
        padding: 0px;
    }
    .auto-container{
        padding: 0px;
    }
    .user_name_span{
        max-width: 110px;
        overflow-x: hidden;
    }
}

.sidebar{
    overflow: hidden !important;
    background-color: #fff;
}
.sidebar .nav-link:hover{
    background-color: #ff5773;
}
.sidebar .nav-link.active{
    background-color: #ff5773;
}
.sidebar-minimized .sidebar .nav-item:hover > .nav-link{
    background-color: #ff5773;
}
.sidebar-minimized .sidebar .sidebar-minimizer{
    background-color: #ff5773;
}
.sidebar-minimized.sidebar-lg-show .sidebar .sidebar-minimizer{
    //position: fixed !important;
    right: 0px;
}
.sidebar-minimized .sidebar .sidebar-minimizer{
    //position: relative !important;
    right: -300px;
}
.sidebar .sidebar-minimizer{
    background-color: #ff5773;
}
.sidebar .sidebar-minimizer:hover{
    background-color: #ff5773;
}
.sidebar .sidebar-minimizer::before{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.sidebar-minimized .sidebar .sidebar-minimizer::before{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.sidebar .nav-link{
    color: #333;
}
.sidebar .nav-link.active .nav-icon{
    color: #fff;
}
.InputRespond .fa{
    position: absolute;
    left: 15px;
    top: 13px;
    font-size: 20px;
    color: #ff5773;
}
.btn:disabled{
    cursor: not-allowed;
}
.sidebar-page-container{
    padding: 15px 0px 15px;
}

.featured-section{
    margin-top: 15px;
}
.video_box > div{
    margin: auto;
    width: 100%;
}
.video-js.vjs-has-started.vjs-playing button.vjs-big-play-button{
    display: none;
}
.video-js button.vjs-big-play-button{  
    display: block;
    //position: relative;
    width: 55px;
    height: 55px;
    z-index: 99;
    color: #ff5773;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    border-radius: 50%;
    padding-left: 4px;
    line-height: 40px;
    display: inline-block;
    background-color: #ffffff;
    transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
    
}
.video-js button.vjs-big-play-button:hover{
    box-shadow: 0 0 0 0 rgba(255,87,115, .6);
    background-color: #ff5773 !important;
    border-color: #ff5773 !important;
    color: #fff !important;
}
.notifications_div strong{
    color: #ff5773 !important;
}
span.theme_color > a{
    color: #ff5773 !important;
}
.video-js:hover button.vjs-big-play-button{
    width: 55px;
    height: 55px;
    z-index: 99;
    color: #ff5773;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    border-radius: 50%;
    padding-left: 4px;
    line-height: 40px;
    display: inline-block;
    background-color: #ffffff;
}
.video-js button.vjs-big-play-button::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 0.06666em solid #fff;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
}

.video-js button.vjs-big-play-button::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 0.06666em solid #fff;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
}
.video-js button.vjs-big-play-button:hover::before {
    box-shadow: 0 0 0 0 rgba(255,87,115, .6);
    border-color: #ff5773 !important;
}

.video-js button.vjs-big-play-button:hover::after {
    box-shadow: 0 0 0 0 rgba(255,87,115, .6);
    border-color: #ff5773 !important;
}
@-webkit-keyframes ripple {
    70% {box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);}
    100% {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}

@keyframes ripple {
    70% {box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);}
    100% {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}
.vjs-big-play-centered .vjs-big-play-button{
    margin: 0px !important;
    transform: translate(-50%, -50%);
}
.video-js button.vjs-big-play-button > span{
    line-height: 52px;
}
.course-tabs .tab-btns .tab-btn{
    float: right;
    margin-right: 0px;
    margin-left: 15px;
}

.sidebar.sticky-top{
    margin: 0px !important;
    width: auto !important;
    height: auto !important;
    position: static !important;
    background: transparent !important;
}
.review-widget .intro-video{
    height: 150px;
}
.review-widget .intro-video:before{
    background-color: transparent;
}
.review-widget .price{
    margin-bottom: 25px;
    color: #ff5773 !important;
    del{
        color: #0f0e2d;
    }
}
.priceTxt{
    color: #ff5773 !important;
    del{
        color: #0f0e2d;
    }
}
.inner-box .price{
    color: #ff5773 !important;
    del{
        color: #0f0e2d;
    }
}
.sec-title h4{
    display: inline-block;
}
.feature-block-two .inner-box .content .image{
    left: auto;
    right: 0px;
}
.feature-block-two .inner-box .content{
    padding-right: 130px;
    padding-left: 0px;
}
.feature-block-two .inner-box .text{
    max-height: 75px;
    overflow: auto;
}
@media only screen and (max-width: 479px){
    .feature-block-two .inner-box .content .image, .feature-block-two .inner-box .content .image img {
        width: 100%;
    }
    .feature-block-two .inner-box .content{
        padding-right: 0px;
    }
}



.noResults{
    padding: 75px 0px;
    text-align: center;
    font-size: 24px;
}
.subTitle{
    text-align: right;
    font-size: 16px;
    padding: 15px 15px;
}
.minHeaderSearch{
    display: none;
}
@media (max-width: 991px){
    // .app{
    //     background-color: #fff;
    // }
    // .main{
    //     background: #f7f8fc;
    // }
    // .app-header{
    //     border: none;
    // }
    .header-fixed .app-body{
        margin-top: 155px !important;
    }
    .headerSearch{
        display: none;
    }
    .minHeaderSearch{
        display: block;
        max-width: calc(100% - 30px);
        margin: 0px 15px;
        position: absolute !important;
        top: 90px;
        input{
            max-width: 100%;
        }
    }
}
input{
    position: relative;
    line-height: 30px;
    padding: 10px 50px 10px 30px;
    background: #feeff2;
    display: block;
    font-size: 15px;
    width: 100%;
    height: 50px;
    color: #000000;
    width: 400px;
    font-weight: 400;
    border-radius: 50px;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    font-family: 'Roboto', sans-serif;
}
.headerSearch{
    position: relative;
}
.headerSearch .searchIcon{
    position: absolute;
    top: 13px;
    left: 15px;
    color: #ff5773;
    background: transparent;
    font-size: 18px;
    transition: all ease-in-out .3s;
}
.headerSearch .searchIcon:hover{
    transform: scale(1.1, 1.1);
}

.lightbox-image{
    &:hover{
        cursor: pointer;
    }
}

.noDataDiv{
    text-align: center;
    padding: 30px;
    font-size: 18px;
}


















//notifications 
.aside-menu-off-canvas .app-header + .app-body .aside-menu{
    height: 100vh;
}
.aside-menu-off-canvas .app-header + .app-body .aside-menu > .nav.nav-tabs{
    height: 57px;
}
.aside-menu-fixed .aside-menu .tab-content{
    height: calc(100vh - 75px);
}
@media (max-width:767px){
    .app-header .navbar-nav{
        margin-left: 0px !important;
    }
    .app-header .nav-item.merchantNameMenu{
        width: 50px;
        height: 57px;
    }
    .app-header .nav-item.merchantNameMenu > a{
        overflow: hidden;
        height: 57px;
        padding: 13px 0px !important;
    }
    .aside-menu-fixed .aside-menu .tab-content{
        height: calc(100vh - 154px);
    }
}
.nofiBtn{
    position: relative;
    color: #2C3E56;
    height: 75px;
    width: 60px;
    border-radius: 0px;
}
.nofiBtn > i{
    color: #2C3E56;
}
.nofiBtn:focus{
    outline: none;
    background: #D9DEE4 !important;
}
.nofiBtn:hover{
    outline: none;
    background: #D9DEE4 !important;
}
.nofiBtn > span{
    position: absolute;
    top: 14px;
    right: 8px;
    font-size: 11px;
    padding: 2px;
    color: #2C3E56;
    background: #00E597;
    border-radius: 50%;
    height: 15px;
    width: 15px;
}
.notiTextDiv{
    max-height: 42px;
    overflow: hidden;
}
.markAllBtn:hover{
    cursor: pointer;
}
.noNotificationsDiv{
    text-align: center;
    height: 100%;
}
.noNotificationsDiv > div{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    transform: translateY(-60%);
    color: #999;
}
.noNotificationsDiv > div > i{
    font-size: 100px;
    margin-bottom: 15px;
    color: #ccc;
    opacity: 0.5;
}
.readIconDiv{
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 15px;
}
.readIconDiv:hover{
    cursor: pointer;
}
.readIconDiv > i{
    font-size: 15px;
    transition: all 0.5 ease-in-out;
}
.list-group-item-action:active{
    color: #5c6873;
    background-color: #f0f3f5;
}
.activeIcone{
    color: #00A2E3;
}
.activeBlock{
    background-color: rgba(0, 162, 227,.1);
}
.activeBlock:hover{
    background-color: rgba(0, 162, 227,.1);
}
.readBlock{
    background-color: #f0f3f5;
}
.list-group-accent .list-group-item.list-group-item-divider{
    transition: all 0.5s ease-in-out;
}
.list-group-accent .list-group-item.list-group-item-divider:hover{
    cursor: pointer;
    background-color: #fff;
    
    box-shadow: 2px 2px 15px #999;
}
.list-group-accent .list-group-item.list-group-item-divider.readBlock:hover{
    background-color: #f0f3f5;
}
.list-group-accent .list-group-item.list-group-item-divider.activeBlock:hover{
    background-color: rgba(0, 162, 227,.1);
}
.cursor-pointer:hover{
    cursor: pointer
}
.fullNotificationData{
    padding: 15px;
}
.sidebar-side.mini{
    display: none;
}
@media (max-width:479px){
    .sidebar-side{
        display: none;
    }
    .sidebar-side.mini{
        display: block;
    }
    .sidebar-side.buy_course{
        display: block;
    }

    .courses_container{
        display: flex;
        flex-wrap: unset;
        overflow-x: auto;
    }
    .courses_container > .single_course_div{
        padding-top: 15px;
    }
    .courses_container > .single_course_div > a  > .inner-box{
        width: 250px;
    }
    .courses_container > .single_course_div > a  > .inner-box .image{
        background-color: transparent;
    }
    .courses_container > .single_course_div > a  > .inner-box .image img{
        height: 100px;
        width: auto !important;
        margin: auto;
    }
    .courses_container > .single_course_div > a  > .inner-box .text{
        text-align: center;
        //height: 50px;
        overflow: hidden;
    }
    .courses_container > .single_course_div > a  > .inner-box h6{
        text-align: center;
        //height: 25px;
        overflow: hidden;
    }
    .courses_container > .single_course_div > a  > .inner-box .author{
        text-align: center;
    }
}


.profile_logo{
    width: 150px;
    height: 150px;
    border: 2px solid #999;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    margin: auto;
}
.profile_logo .defualt_icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.profile_logo .defualt_icon > i{
    font-size: 100px;
    color: #999;
}

.profile_logo > img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.profile_logo .update_profile_logo{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 99;
    cursor: pointer;
}
.profile_logo:hover .update_profile_logo i{
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 24px;
}
.profile_logo:hover .update_profile_logo{
    background-color: rgba(0, 0, 0,.3);
    i{
        display: inline-block;
    }
}

.modal-header .close{
    margin-left: 0px;
    padding: 1rem 0rem;
}


.head_profile_img{
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    border-radius: 100%;
    background: #eee;
    img{
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    span{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
    }
}
.modal{
    position: fixed !important;
    z-index: 1050 !important;
}
.modal-dialog{
    //max-width: calc(100% - 30px) !important;
}
.modal-content, .modal-card{
    width: auto !important;
}
.logout_model .modal-body{
    text-align: center;
}
.logout_model .modal-footer{
    text-align: center;
    display: block;
    button{
        margin: 0px 7px;
        border: none;
        padding: 7px 40px;
        font-size: 14px;
        line-height: 30px;
        border-radius: 50px;
    }
}

.feature-block-two .inner-box h6{
    font-size: 20px !important;
    color: #ff5773;
}
.announcements_title{
    color: #ff5773;
}